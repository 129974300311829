angular
	.module('annexaApp')
	.component('annexaAddressTerritorialGroups', {
		templateUrl: './components/common/annexa-address-territorial-groups/annexa-address-territorial-groups.html',
		controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'TerritoryFactory', 
		function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, TerritoryFactory) {
			var vm = this;
			vm.languageColumn = Language.getActiveColumn();

			vm.changeTerritorialGroup = function (group, value) {
				if(vm.cityId && group){
					var updateTG = undefined;
					if(group.actual){
						updateTG = angular.copy(group.actual);
						updateTG.streetCity = { id: vm.cityId };
						updateTG.territorialGroup = ((group.value.id)?{id:group.value.id}:undefined);
					}else{
						if(group.value.id){
							updateTG = {
								territorialGroup: {id:group.value.id},
								streetCity:{id: vm.cityId}
							}
						}
					}
					if(updateTG && updateTG.id){
						RestService.update('./api/territory/street_city_territorial_group/'+updateTG.id, updateTG).then(function(data) {
							if(data && data.id){
								group.actual = data;
							}else{
								group.actual = undefined;
							}
						}).catch(function() {
							if(group.values && group.actual && group.actual.territorialGroup && group.actual.territorialGroup.id){
								group.value = $linq(group.values).firstOrDefault(undefined, "x => x.id == "+group.actual.territorialGroup.id);
							}else{
								group.value = group.values[0]; 
							}
							DialogsFactory.error($filter('translate')('global.errors.errorCity'));
						});
					}else{
						RestService.insert('./api/territory/street_city_territorial_group', updateTG).then(function(data) {
							if(data && data.id){
								group.actual = data;
							}else{
								group.actual = undefined;
							}
						}).catch(function() {
							if(group.values && group.actual && group.actual.territorialGroup && group.actual.territorialGroup.id){
								group.value = $linq(group.values).firstOrDefault(undefined, "x => x.id == "+group.actual.territorialGroup.id);
							}else{
								group.value = group.values[0]; 
							}
							DialogsFactory.error($filter('translate')('global.errors.errorCity'));
						});
					}
				}
			}

			this.$onInit = function(){
				vm.territorialAddGroups = [];
				if(vm.territorialGroups && vm.territorialGroups.length > 0){
					vm.territorialAddGroups = angular.copy(vm.territorialGroups);
					vm.territorialGroups.length = 0;
				}else if(!vm.territorialGroups){
					vm.territorialGroups = [];
				}
				var territorialGroupTypes = angular.copy(TerritoryFactory.territorialGroupTypes);
				if (vm.applicableTo)
					territorialGroupTypes = $linq(TerritoryFactory.territorialGroupTypes).where("x => x.applicableTo == '" + vm.applicableTo + "'").toArray();
				
				if(territorialGroupTypes){
					_.forEach(territorialGroupTypes, function(type){
						var tgActual = undefined;
						var values = $linq(TerritoryFactory.territorialGroups).where("x => x.territorialGroupType && x.territorialGroupType.id == "+type.id).toArray();
						if(!values){
							values = [{id:undefined, language1:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty'), language3:$filter('translate')('global.literals.empty')}];
						}else{
							values.unshift({id:undefined, language1:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty'), language3:$filter('translate')('global.literals.empty')});
						}
						var value = undefined;
						if(vm.territorialAddGroups && vm.territorialAddGroups.length > 0){
							var tgActuals = $linq(vm.territorialAddGroups).where("x => x.territorialGroup && x.territorialGroup.territorialGroupType && x.territorialGroup.territorialGroupType.id == "+type.id).toArray();
							if(tgActuals && tgActuals.length > 0 && tgActuals[0].territorialGroup){
								tgActual = tgActuals[0];
								value = $linq(values).firstOrDefault(undefined, "x => x.id == "+tgActuals[0].territorialGroup.id);
							}
						}
						if(!value) {
							value = values[0];
						}
						vm.territorialGroups.push({
							type: type,
							value: value,
							values: values,
							actual: tgActual
						});
					});
				}
			}
		}],
		bindings: {
			territorialGroups: '=',
			isNew: '=',
			applicableTo: '=',
			cityId:'=?',
			streetNumbersId:'=?'
		}
	})