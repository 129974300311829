angular
    .module('annexaApp')
    .component('annexaCouncillorProposalType',{
        templateUrl: './components/common/annexa-councillor-proposal-type/annexa-councillor-proposal-type.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

        	vm.getProposalType = function(proposalType){
				if(proposalType && proposalType[vm.languageColumn]){
					return proposalType[vm.languageColumn];
				}else{
					return '';
				}
			}
            vm.getCouncillorProposalTypeDelegationType = function(councillorProposalTypeDelegationType){
				if(councillorProposalTypeDelegationType && councillorProposalTypeDelegationType[vm.languageColumn]){
					return councillorProposalTypeDelegationType[vm.languageColumn];
				}else{
					return '';
				}
			}
            
			vm.getPresetSignCircuit = function(presetSignCircuit){
				if(presetSignCircuit && presetSignCircuit[vm.languageColumn]){
					return presetSignCircuit[vm.languageColumn];
				}else{
					return '';
				}
			}
            
			vm.getDecreeOrOrgan = function(item){
				if(item){
					if(item.councillorProposalTypeDelegationOrgans && item.councillorProposalTypeDelegationOrgans.length > 0){
						return $filter('translate')('global.commonAdmin.literals.proposalTypeOrgan')+' '+item.councillorProposalTypeDelegationOrgans.length;
					}else if(item.councillorProposalTypeDelegationDecrees && item.councillorProposalTypeDelegationDecrees.length > 0) {
						return $filter('translate')('global.commonAdmin.literals.delegationDecrees')+' '+item.councillorProposalTypeDelegationDecrees.length;
					}else{
						return '';
					}
				}else{
					return '';
				}
			}
	                

			vm.saveNewProposalType = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var councillorProposalType = {
						proposalType: ((model.proposalType)?$linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined,"x => x.id == "+model.proposalType):undefined),
						councillorProposalTypeDelegationType: ((model.councillorProposalTypeDelegationType)?$linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined,"x => x.id == "+model.councillorProposalTypeDelegationType):undefined),
						presetSignCircuit: ((model.presetSignCircuit)?$linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined,"x => x.id == "+model.presetSignCircuit):undefined),
						councillorProposalTypeDelegationDecrees:  [],
						councillorProposalTypeDelegationOrgans:  []
					}
					var proposalTypeOk = true;
					if(councillorProposalType.proposalType){ 
						if(councillorProposalType.proposalType.proposalTypeSubtype === 'DECREE'){
							var decreeField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationDecrees'");
					        if(decreeField && decreeField.data && decreeField.data.councillorProposalTypeDelegationDecrees){
					            councillorProposalType.councillorProposalTypeDelegationDecrees = decreeField.data.councillorProposalTypeDelegationDecrees;
					        }
						}else{
							var organField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationOrgans'");
					        if(organField && organField.data && organField.data.councillorProposalTypeDelegationOrgans){
					            councillorProposalType.councillorProposalTypeDelegationOrgans = organField.data.councillorProposalTypeDelegationOrgans;
					        }
						}
					}
					if(proposalTypeOk){
						if(vm.isNew){
							vm.councillorProposalTypes.push(councillorProposalType);
							self.close();
						}else{
							councillorProposalType.councillor = ((vm.object && vm.object.id)?{id:vm.object.id}:undefined);
							RestService.insert('./api/councillor_proposal_type', councillorProposalType).then(function (data) {
								vm.councillorProposalTypes.push(data);
				        		self.close();
				            }).catch(function (error) {
				            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
				            });
						}
					}
				}
			}
			
			vm.deleteProposalType = function(item, index){
				if(vm.isNew){
					vm.councillorProposalTypes.splice(index, 1);
				}else{
					if(item && item.id){
						RestService.delete('./api/councillor_proposal_type/' + item.id).then(function (data) {
		        			vm.councillorProposalTypes.splice(index, 1);
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
			            });
					}else{
						vm.councillorProposalTypes.splice(index, 1);
					}
				}	
			}
			
			vm.newProposalType = function(){
				var modal = angular.copy(CommonAdminModals.councillorProposalTypeNew);
				modal.annexaFormly.options = {};
				AnnexaFormlyFactory.showModal('modalCouncillorProposalTypes', modal, vm.saveNewProposalType, false);
			}
			
			vm.saveEditProposalType = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var itemAux = angular.copy(this.temp.item);
					if(!vm.isNew){
						itemAux.proposalType = ((model.proposalType)?{id:model.proposalType}:undefined);
						itemAux.councillorProposalTypeDelegationType = ((model.councillorProposalTypeDelegationType)?{id:model.councillorProposalTypeDelegationType}:undefined);
						itemAux.presetSignCircuit = ((model.presetSignCircuit)?{id:model.presetSignCircuit}:undefined);
						itemAux.councillorProposalTypeDelegationDecrees = [];
						itemAux.councillorProposalTypeDelegationOrgans = [];
						if(itemAux.proposalType){ 
							if(itemAux.proposalType.proposalTypeSubtype === 'DECREE'){
								var decreeField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationDecrees'");
						        if(decreeField && decreeField.data && decreeField.data.councillorProposalTypeDelegationDecrees){
						            itemAux.councillorProposalTypeDelegationDecrees = decreeField.data.councillorProposalTypeDelegationDecrees;
						        }
							}else{
								var organField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationOrgans'");
						        if(organField && organField.data && organField.data.councillorProposalTypeDelegationOrgans){
						            itemAux.councillorProposalTypeDelegationOrgans = organField.data.councillorProposalTypeDelegationOrgans;
						        }
							}
						}
					}else{
						self.temp.item.proposalType = ((model.proposalType)?$linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined,"x => x.id == "+model.proposalType):undefined);
						self.temp.item.councillorProposalTypeDelegationType = ((model.councillorProposalTypeDelegationType)?$linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined,"x => x.id == "+model.councillorProposalTypeDelegationType):undefined);
						self.temp.item.presetSignCircuit = ((model.presetSignCircuit)?$linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined,"x => x.id == "+model.presetSignCircuit):undefined);
						self.temp.item.councillorProposalTypeDelegationDecrees = [];
						self.temp.item.councillorProposalTypeDelegationOrgans = [];
						if(self.temp.item.proposalType){ 
							if(self.temp.item.proposalType.proposalTypeSubtype === 'DECREE'){
								var decreeField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationDecrees'");
						        if(decreeField && decreeField.data && decreeField.data.councillorProposalTypeDelegationDecrees){
						            self.temp.item.councillorProposalTypeDelegationDecrees = decreeField.data.councillorProposalTypeDelegationDecrees;
						        }
							}else{
								var organField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationOrgans'");
						        if(organField && organField.data && organField.data.councillorProposalTypeDelegationOrgans){
						            self.temp.item.councillorProposalTypeDelegationOrgans = organField.data.councillorProposalTypeDelegationOrgans;
						        }
							}
						}
					}
					var proposalTypeOk = true;
					if(proposalTypeOk){
						if(vm.isNew){
							self.close();
						}else{
							RestService.update('./api/councillor_proposal_type/' + itemAux.id, itemAux).then(function (data) {
								self.temp.item.proposalType = ((model.proposalType)?$linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined,"x => x.id == "+model.proposalType):undefined);
								self.temp.item.councillorProposalTypeDelegationType = ((model.councillorProposalTypeDelegationType)?$linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined,"x => x.id == "+model.councillorProposalTypeDelegationType):undefined);
								self.temp.item.presetSignCircuit = ((model.presetSignCircuit)?$linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined,"x => x.id == "+model.presetSignCircuit):undefined);
								self.temp.item.councillorProposalTypeDelegationDecrees = [];
								self.temp.item.councillorProposalTypeDelegationOrgans = [];
								if(self.temp.item.proposalType){ 
									if(self.temp.item.proposalType.proposalTypeSubtype === 'DECREE'){
										var decreeField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationDecrees'");
								        if(decreeField && decreeField.data && decreeField.data.councillorProposalTypeDelegationDecrees){
								            self.temp.item.councillorProposalTypeDelegationDecrees = decreeField.data.councillorProposalTypeDelegationDecrees;
								        }
									}else{
										var organField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationOrgans'");
								        if(organField && organField.data && organField.data.councillorProposalTypeDelegationOrgans){
								            self.temp.item.councillorProposalTypeDelegationOrgans = organField.data.councillorProposalTypeDelegationOrgans;
								        }
									}
								}
				        		self.close();
				            }).catch(function (error) {
				            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
				            });
						}
					}
				}
			}
			
			vm.editProposalType = function(item){
				var modal = angular.copy(CommonAdminModals.councillorProposalTypeNew);
				modal.annexaFormly.options = {};
				modal.temp = {item:item};
				modal.annexaFormly.model = {modal_body: {
					proposalType: ((item && item.proposalType && item.proposalType.id)?item.proposalType.id:undefined),
					councillorProposalTypeDelegationType: ((item && item.councillorProposalTypeDelegationType && item.councillorProposalTypeDelegationType.id)?item.councillorProposalTypeDelegationType.id:undefined),
					presetSignCircuit: ((item && item.presetSignCircuit && item.presetSignCircuit.id)?item.presetSignCircuit.id:undefined)
				}}
				var decreeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationDecrees'");
		        if(decreeField && decreeField.data){
		            decreeField.data.councillorProposalTypeDelegationDecrees = ((item && item.councillorProposalTypeDelegationDecrees)?angular.copy(item.councillorProposalTypeDelegationDecrees):[]);
					decreeField.data.isNew = vm.isNew; 
					decreeField.data.councillorProposalType = ((item && item.id)?item.id:undefined);
		        }
				var organField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'councillorProposalTypeDelegationOrgans'");
		        if(organField && organField.data){
		            organField.data.councillorProposalTypeDelegationOrgans = ((item && item.councillorProposalTypeDelegationOrgans)?angular.copy(item.councillorProposalTypeDelegationOrgans):[]);
					organField.data.isNew = vm.isNew;
					organField.data.councillorProposalType = ((item && item.id)?item.id:undefined);;
		        }
				AnnexaFormlyFactory.showModal('modalCouncillorProposalTypesEdit', modal, vm.saveEditProposalType, false);
			}

        	this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.councillorProposalTypes){
					vm.councillorProposalTypes = [];
				}
				
				$scope.$on('saveListsCouncillorProposalTypesModal', function (event, args) {
		            if(args && args.modalId && args.listToModify && args.objectId){
						var object = $linq(vm.councillorProposalTypes).firstOrDefault(undefined, "x => x.id == "+args.objectId);
						if(object){
							if(args.modalId === 'Organ'){
								if(object.councillorProposalTypeDelegationOrgans){
									object.councillorProposalTypeDelegationOrgans.length = 0;
								}else{
									object.councillorProposalTypeDelegationOrgans = [];
								}
								if(object.councillorProposalTypeDelegationDecrees){
									object.councillorProposalTypeDelegationDecrees.length = 0;
								}else{
									object.councillorProposalTypeDelegationDecrees = [];
								}
								_.forEach(args.listToModify, function(ltm){
									object.councillorProposalTypeDelegationOrgans.push(ltm);
								});
							}else if(args.modalId === 'Decree'){
								if(object.councillorProposalTypeDelegationDecrees){
									object.councillorProposalTypeDelegationDecrees.length = 0;
								}else{
									object.councillorProposalTypeDelegationDecrees = [];
								}
								if(object.councillorProposalTypeDelegationOrgans){
									object.councillorProposalTypeDelegationOrgans.length = 0;
								}else{
									object.councillorProposalTypeDelegationOrgans = [];
								}
								_.forEach(args.listToModify, function(ltm){
									object.councillorProposalTypeDelegationDecrees.push(ltm);
								});
							}
						}
					}
		        })
            }
        }],
        bindings: {
            object: '=',
            isNew: '=?',
			councillorProposalTypes: '='
        }
    })
	.component('annexaCouncillorProposalTypeOrgan',{
        templateUrl: './components/common/annexa-councillor-proposal-type/annexa-councillor-proposal-type-organ.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
	        vm.getOrgan = function(organ){
				if(organ && organ[vm.languageColumn]){
					return organ[vm.languageColumn];
				}else{
					return '';
				}
			}
			vm.getDelegated = function(delegated){
				if(delegated){
					return $filter('translate')('global.literals.yes');
				}else{
					return $filter('translate')('global.literals.no');
				}
			}
			vm.getDelegationTypes = function(councillorProposalTypeDelegationOrganDelegationTypes){
				if(councillorProposalTypeDelegationOrganDelegationTypes && councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
					var text = '';
					_.forEach(councillorProposalTypeDelegationOrganDelegationTypes, function(cptdodt){
						if(cptdodt.councillorProposalTypeDelegationType && cptdodt.councillorProposalTypeDelegationType[vm.languageColumn]){
							text += ((text)?', ':'')+cptdodt.councillorProposalTypeDelegationType[vm.languageColumn]; 
						}
					})
					return text;
				}else{
					return '';
				}	
			}
			
			vm.saveNewOrgan = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var organ = {
						organ: model.organ,
						delegated: ((model.delegated)? true: false),
						councillorProposalTypeDelegationOrganDelegationTypes:[]
					}
					if(model.delegationTypes && model.delegationTypes.length > 0){
						_.forEach(model.delegationTypes, function(dt){
							var dtAux = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined, "x=> x.id == "+dt);
							if(dtAux){
								organ.councillorProposalTypeDelegationOrganDelegationTypes.push({councillorProposalTypeDelegationType: dtAux});
							}
						});
					}
					var organOk = true;
					if(organOk){
						if(vm.isNew){
							vm.councillorProposalTypeDelegationOrgans.push(organ);
							self.close();
						}else{
							if(vm.councillorProposalType){
								organ.councillorProposalType = {id:vm.councillorProposalType};
								RestService.insert('./api/councillor_proposal_type/delegation_organ', organ).then(function (data) {
									vm.councillorProposalTypeDelegationOrgans.push(data);
									$rootScope.$broadcast('saveListsCouncillorProposalTypesModal',{modalId:'Organ', listToModify:angular.copy(vm.councillorProposalTypeDelegationOrgans), objectId:vm.councillorProposalType});
					        		self.close();
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
					            });
							}else{
								vm.councillorProposalTypeDelegationOrgans.push(organ);
								self.close();
							}
						}
					}
				}
			}
        	
			vm.newOrgan = function(){
				var modal = angular.copy(CommonAdminModals.councillorProposalTypesOrganAdd);
	            modal.annexaFormly.model = {};
				modal.annexaFormly.options = { watchAllExpressions: true };
	            AnnexaFormlyFactory.showModal('modalCouncillorProposalTypesOrganAdd', modal, vm.saveNewOrgan, false);
			}
			
			vm.saveEditOrgan = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var itemAux = angular.copy(this.temp.item);
					if(!vm.isNew && vm.councillorProposalType){
						itemAux.organ = ((model.organ && model.organ.id)?{id:model.organ.id}:undefined);
						itemAux.delegated = ((model.delegated)? true: false);
						itemAux.councillorProposalTypeDelegationOrganDelegationTypes = [];
						if(model.delegationTypes && model.delegationTypes.length > 0){
							_.forEach(model.delegationTypes, function(dt){
								var dtAux = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined, "x=> x.id == "+dt);
								if(dtAux){
									itemAux.councillorProposalTypeDelegationOrganDelegationTypes.push({councillorProposalTypeDelegationType: {id:dtAux.id}});
								}
							});
						}
					}else{
						self.temp.item.organ = model.organ;
						self.temp.item.delegated = ((model.delegated)? true: false);
						self.temp.item.councillorProposalTypeDelegationOrganDelegationTypes = [];
						if(model.delegationTypes && model.delegationTypes.length > 0){
							_.forEach(model.delegationTypes, function(dt){
								var dtAux = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined, "x=> x.id == "+dt);
								if(dtAux){
									self.temp.item.councillorProposalTypeDelegationOrganDelegationTypes.push({councillorProposalTypeDelegationType: dtAux});
								}
							});
						}
					}
					var organOk = true;
					if(organOk){
						if(vm.isNew){
							self.close();
						}else{
							if(vm.councillorProposalType){
								RestService.update('./api/councillor_proposal_type/delegation_organ/' + itemAux.id, itemAux).then(function (data) {
					        		self.temp.item.organ = model.organ;
									self.temp.item.delegated = ((model.delegated)? true: false);
									self.temp.item.councillorProposalTypeDelegationOrganDelegationTypes = [];
									if(model.delegationTypes && model.delegationTypes.length > 0){
										_.forEach(model.delegationTypes, function(dt){
											var dtAux = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined, "x=> x.id == "+dt);
											if(dtAux){
												self.temp.item.councillorProposalTypeDelegationOrganDelegationTypes.push({councillorProposalTypeDelegationType: dtAux});
											}
										});
									}
									$rootScope.$broadcast('saveListsCouncillorProposalTypesModal',{modalId:'Organ', listToModify:angular.copy(vm.councillorProposalTypeDelegationOrgans), objectId:vm.councillorProposalType});
									self.close();
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
					            });
							}else{
								self.close();			
							}
						}
					}
				}
			}
			
			vm.editOrgan = function(item){
				var modal = angular.copy(CommonAdminModals.councillorProposalTypesOrganAdd);
				modal.temp = {item:item};
				modal.annexaFormly.options = { watchAllExpressions: true };
	            modal.annexaFormly.model = {modal_body: {
					organ: angular.copy(item.organ),
					delegated: ((item.delegated)? true: false),
					delegationTypes:[]
				}};
				if(item.councillorProposalTypeDelegationOrganDelegationTypes && item.councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
					_.forEach(item.councillorProposalTypeDelegationOrganDelegationTypes, function(dt){
						if(dt.councillorProposalTypeDelegationType && dt.councillorProposalTypeDelegationType.id){
							modal.annexaFormly.model.modal_body.delegationTypes.push(dt.councillorProposalTypeDelegationType.id);
						}
					});
				}
	            AnnexaFormlyFactory.showModal('modalCouncillorProposalTypesOrganEdit', modal, vm.saveEditOrgan, false);
			}
			
			vm.deleteOrgan = function(item,index){
				if(vm.isNew){
					vm.councillorProposalTypeDelegationOrgans.splice(index, 1);
				}else{
					if(vm.councillorProposalType){
						RestService.delete('./api/councillor_proposal_type/delegation_organ/' + item.id).then(function (data) {
			        		if(data){
								vm.councillorProposalTypeDelegationOrgans.splice(index, 1);
								$rootScope.$broadcast('saveListsCouncillorProposalTypesModal',{modalId:'Organ', listToModify:angular.copy(vm.councillorProposalTypeDelegationOrgans), objectId:vm.councillorProposalType});
							}else{
								DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
							}
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
			            });
					}else{
						vm.councillorProposalTypeDelegationOrgans.splice(index, 1);
					}
				}
				
			}
        	
			this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.councillorProposalTypeDelegationOrgans){
					vm.councillorProposalTypeDelegationOrgans = [];
				}
            }
        }],
        bindings: {
            isNew: '=?',
			councillorProposalType: '=',
			councillorProposalTypeDelegationOrgans: '='
        }
    })
	.component('annexaCouncillorProposalTypeDecree',{
        templateUrl: './components/common/annexa-councillor-proposal-type/annexa-councillor-proposal-type-decree.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.saveNewDecree = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var decree = {
						decreeDate: model.decreeDate,
						decreeNumber: model.decreeNumber,
						decreeOfficialDiary: model.decreeOfficialDiary
					}
					var decreeOk = true;
					if(decreeOk){
						if(vm.isNew){
							vm.councillorProposalTypeDelegationDecrees.push(decree);
							self.close();
						}else{
							if(vm.councillorProposalType){
								decree.councillorProposalType = {id:vm.councillorProposalType};
								RestService.insert('./api/councillor_proposal_type/delegation_decree', decree).then(function (data) {
									vm.councillorProposalTypeDelegationDecrees.push(data);
									$rootScope.$broadcast('saveListsCouncillorProposalTypesModal',{modalId:'Decree', listToModify:angular.copy(vm.councillorProposalTypeDelegationDecrees), objectId:vm.councillorProposalType});
									self.close();
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
					            });
							}else{
								vm.councillorProposalTypeDelegationDecrees.push(decree);
								self.close();
							}
						}
					}
				}
			}
        	
			vm.newDecree = function(){
				var modal = angular.copy(CommonAdminModals.councillorProposalTypesDecreeAdd);
	            modal.annexaFormly.model = {};
				modal.annexaFormly.options = { watchAllExpressions: true };
	            AnnexaFormlyFactory.showModal('modalCouncillorProposalTypesDecreeAdd', modal, vm.saveNewDecree, false);
			}
			
			vm.saveEditDecree = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var itemAux = angular.copy(this.temp.item);
					if(!vm.isNew && vm.councillorProposalType){
						itemAux.decreeDate = model.decreeDate;
						itemAux.decreeNumber = model.decreeNumber;
						itemAux.decreeOfficialDiary = model.decreeOfficialDiary;
					}else{
						self.temp.item.decreeDate = model.decreeDate;
						self.temp.item.decreeNumber = model.decreeNumber;
						self.temp.item.decreeOfficialDiary = model.decreeOfficialDiary;
					}
					var decreeOk = true;
					if(decreeOk){
						if(vm.isNew){
							self.close();
						}else{
							if(vm.councillorProposalType){
								RestService.update('./api/councillor_proposal_type/delegation_decree/' + itemAux.id, itemAux).then(function (data) {
					        		self.temp.item.decreeDate = model.decreeDate;
									self.temp.item.decreeNumber = model.decreeNumber;
									self.temp.item.decreeOfficialDiary = model.decreeOfficialDiary;
									$rootScope.$broadcast('saveListsCouncillorProposalTypesModal',{modalId:'Decree', listToModify:angular.copy(vm.councillorProposalTypeDelegationDecrees), objectId:vm.councillorProposalType});
									self.close();
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
					            });
							}else{
								self.close();			
							}
						}
					}
				}
			}
			
			vm.editDecree = function(item){
				var modal = angular.copy(CommonAdminModals.councillorProposalTypesDecreeAdd);
				modal.temp = {item:item};
				modal.annexaFormly.options = { watchAllExpressions: true };
	            modal.annexaFormly.model = {modal_body: {
					decreeDate: item.decreeDate,
					decreeNumber: item.decreeNumber,
					decreeOfficialDiary:item.decreeOfficialDiary
				}};
	            AnnexaFormlyFactory.showModal('modalCouncillorProposalTypesDecreeEdit', modal, vm.saveEditDecree, false);
			}
			
			vm.deleteDecree = function(item,index){
				if(vm.isNew){
					vm.councillorProposalTypeDelegationDecrees.splice(index, 1);
				}else{
					if(vm.councillorProposalType){
						RestService.delete('./api/councillor_proposal_type/delegation_decree/' + item.id).then(function (data) {
			        		if(data){
								vm.councillorProposalTypeDelegationDecrees.splice(index, 1);
								$rootScope.$broadcast('saveListsCouncillorProposalTypesModal',{modalId:'Decree', listToModify:angular.copy(vm.councillorProposalTypeDelegationDecrees), objectId:vm.councillorProposalType});
							}else{
								DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
							}
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
			            });
					}else{
						vm.councillorProposalTypeDelegationDecrees.splice(index, 1);
					}
				}
			}
        	
			this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.councillorProposalTypeDelegationDecrees){
					vm.councillorProposalTypeDelegationDecrees = [];
				}
            }
        }],
        bindings: {
            isNew: '=?',
			councillorProposalType: '=',
			councillorProposalTypeDelegationDecrees: '='
        }
    })