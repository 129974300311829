angular
    .module('annexaApp')
    .component('annexaListValuesTreeCF', {
        templateUrl: './components/common/annexa-list_values_tree-c-f/annexa-list_values_tree-c-f.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', '$q', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, $q) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addFunction = function(item, sameLevel){
        		var modal = angular.copy(CommonAdminModals.addTreeValueCustomField);
        		modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
                modal.extra = { 
                		sessionProperty: 'item', 
                		item: ((item && item.$nodeScope && item.$nodeScope.$modelValue && item.$nodeScope.$modelValue.item)?item.$nodeScope.$modelValue.item : undefined), 
                		sameLevel: sameLevel
                };
                AnnexaFormlyFactory.showModal('modalNewListValueTreeCF', modal, vm.saveNewValue, false);
            }
            
            vm.saveNewValue = function(){
            	if(this && this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
            		if(this.item && this.item.$nodeScope && this.item.$nodeScope.$modelValue && this.item.$nodeScope.$modelValue.item){
            			this.item.$nodeScope.$modelValue.item.language1 = this.annexaFormly.model.modal_body.language1;
            			this.item.$nodeScope.$modelValue.item.language2 = this.annexaFormly.model.modal_body.language2;
            			this.item.$nodeScope.$modelValue.item.language3 = this.annexaFormly.model.modal_body.language3;
            		}else{
            			if(this.extra){
        					var addToOrder = 0;
        					if(vm.data.listValues && vm.data.listValues.length > 0){
        	                	addToOrder = $linq(vm.data.listValues).select("x => x.orderView").max("x => x");
        	                }
        					if(!addToOrder){
        						addToOrder = 0;
        					}
        					var newItem = {
        						language1: this.annexaFormly.model.modal_body.language1,
        						language2: this.annexaFormly.model.modal_body.language2,
        						language3: this.annexaFormly.model.modal_body.language3,
        						orderView: addToOrder+1
        					}
        					var cfValue = 1;
                			if(vm.data && vm.data.listValues && vm.data.listValues.length > 0){
                				cfValue = $linq(vm.data.listValues).max("x => x.value");
                				cfValue = cfValue+1;
                			}
                			newItem.value = cfValue;
            				if(this.extra.item){
                        		if(this.extra.sameLevel == true){
                        			if(this.extra.item.parent){
                        				newItem.parent = {id:this.extra.item.parent.id, value:this.extra.item.parent.value};
                        				newItem.parentValue = {id:this.extra.item.parent.id, value:this.extra.item.parent.value};
                        			}
                        		}else if(this.extra.sameLevel == false){
                        			newItem.parent = {id:this.extra.item.id, value:this.extra.item.value};
                        			newItem.parentValue = {id:this.extra.item.id, value:this.extra.item.value};
                        		}
                        	}
            			}
            			vm.data.listValues.push(newItem);
                        vm.listValuesTree = CommonService.getNestableData(vm.data.listValues, 'item', 'orderView');
            		}
            		this.close();
            	}
            }

        	vm.editFunction = function(item){
            	var modal = angular.copy(CommonAdminModals.addTreeValueCustomField);
            	modal.item = item;
            	if(item && item.$nodeScope && item.$nodeScope.$modelValue && item.$nodeScope.$modelValue.item){
					modal.annexaFormly.model = {modal_body:angular.copy(item.$nodeScope.$modelValue.item)};
            	}
   				modal.annexaFormly.options = { watchAllExpressions: true };
   	            AnnexaFormlyFactory.showModal('modalNewListValueTreeCF', modal, vm.saveNewValue, false);
            }
            
        	vm.removeFunction = function(item){
        		DialogsFactory.confirm('global.literals.confirmRemoveLinkedTitle', 'global.literals.confirmRemoveLinkedBody').then(function (dataAux) {
        			if(item){
        				var itemIndex = -1;
        				if(item.id){
        					itemIndex = $linq(vm.data.listValues).indexOf("x => x.id == "+item.id);
        				}else if(item.value){
        					itemIndex = $linq(vm.data.listValues).indexOf("x => x.value == "+item.value);
        				}
        				if(itemIndex > -1){
        					vm.data.listValues.splice(itemIndex, 1);
        					vm.listValuesTree = CommonService.getNestableData(vm.data.listValues, 'item', 'orderView');
        				}
        				
        			}
        		}).catch(function (data) {
                    //Empty
        		});
            }
        	
        	vm.canDelete = function(item){
        		if(!vm.data.isDisabled || (item && !item.id)){
        			return true;
        		}else{
        			return false;
        		}
        	}
        	vm.calculateOrderViewOther = function(destModelValue, sourceValue){
        		if(destModelValue && sourceValue){
        			var orderView = sourceValue.orderView;
        			var orderViewAux = orderView;
        			if(destModelValue.length > 0){
        				_.forEach(destModelValue, function(val){
        					if(val.item && val.item.orderView != undefined && val.item.orderView >= orderView && ((val.item.id == undefined && sourceValue.id == undefined) || val.item.id !== sourceValue.id) && val.item.value !== sourceValue.value ){
        						orderViewAux = orderViewAux+1;
        						val.item.orderView = orderViewAux;
        						var aux = undefined;
        						if(val.item.id){
        							aux = $linq(vm.data.listValues).indexOf("x => x.id == "+val.item.id);
        						}else if(val.item.value){
        							aux = $linq(vm.data.listValues).indexOf("x => x.value == "+val.item.value);
        						}
        						if(aux > -1){
        							vm.data.listValues[aux].orderView = val.item.orderView;
        						}
        					}
        				});
        			}
        		}
        	}
            this.$onInit = function(){
            	_.forEach(vm.data.listValues, function(val){
            		val.parent = ((val.parentValue && val.parentValue.id)?{id: val.parentValue.id, value: val.parentValue.value, orderView: val.parentValue.orderView}:undefined);
            	});
            	vm.listValuesTree = CommonService.getNestableData(vm.data.listValues, 'item', 'orderView');
                
            	vm.treeOptions = {
    	            beforeDrop: function(e){
    	            	var deferred = $q.defer();
    	            	var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.item: null;
    	            	var destValueOK = true;
	                	if((!destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.item && (!e.source.nodeScope.$modelValue.item.parent || !e.source.nodeScope.$modelValue.item.parent.id)) 
	            				|| (destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.item && e.source.nodeScope.$modelValue.item.parent && destValue.id == e.source.nodeScope.$modelValue.item.parent.id)
	            		){
	            			if(e.dest.index == e.source.index){
	            				destValueOK = false;
	            			}
	            		}
	                	if(destValueOK){
	            			DialogsFactory.confirm('global.literals.movePoint', 'global.literals.confirmmovePoint').then(function (dataAux) {
	    	            		var sourceValue = e.source.nodeScope.$modelValue.item;
	        	                var sourceParentValue = null;
	        	                if( e.source.nodeScope.$modelValue.item.parent != null &&  e.source.nodeScope.$modelValue.item.parent != undefined) {
	        	                    sourceParentValue = e.source.nodeScope.$modelValue.item.parent.id;
	        	                }
	        	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.item: null;
	        	                if(destValue != null && destValue != undefined) {
	    	                        sourceValue.parent = destValue;
	    	                        sourceValue.parentValue = destValue;
	        	                }else {
	        	                    sourceValue.parent = null;
	        	                    sourceValue.parentValue = null;
	        	                }
	        	                sourceValue.orderView = e.dest.index+1;
	        	                var aux = undefined;
        						if(sourceValue.id){
        							aux = $linq(vm.data.listValues).indexOf("x => x.id == "+sourceValue.id);
        						}else if(sourceValue.value){
        							aux = $linq(vm.data.listValues).indexOf("x => x.value == "+sourceValue.value);
        						}
        						if(aux > -1){
        							vm.data.listValues[aux].orderView = sourceValue.orderView;
        							vm.data.listValues[aux].parentValue = sourceValue.parentValue;
        						}
	        	                vm.calculateOrderViewOther(e.dest.nodesScope.$modelValue, sourceValue);
	        	                deferred.resolve(true)
	    	                }).catch(function (error) {
								deferred.reject(false);
	    		            });    	            			
	                	}else{
	                		deferred.reject(false);
	                	}
	                	return deferred.promise;
    	            }
                }
            }
            
        }],
        bindings: {
        	data: '='
        }
    })