angular
    .module('annexaApp')
    .component('annexaLoadDossierRow', {
        templateUrl: './components/common/annexa-load-dossier-row/annexa-load-dossier-row.html',
        controller: ['$rootScope', 'RestService','$scope' ,function($rootScope, RestService, $scope) {
            var vm = this;
            
            vm.inputValue="";
            
            if(!vm.templateOptions.label) {
                vm.templateOptions.label = 'global.literals.documents'
            }
            
            vm.loadFunction = function(value) {
                if(!value && !value.val) {
                    return [];
                }

                if(value.val != '*' && value.val.length < 3) {
                    return [];
                }

                if(value.val == '*') {
                    value.val = '';
                }
                
                if(vm.data.model.otherDossier) {
                	vm.data.model.otherDossier = undefined
                }

                return RestService.loadData('tram', 'Dossier', value.val)
                    .then(function(data) {
                        var dossiers = [];
                        if(data.data && data.data.content && data.data.content.length > 0) {
                            _.forEach(JSOG.decode(data.data.content), function(val) {
                                var value = val.subject + ' (' +  val.dossierNumber + ')';
                                dossiers.push({ 'id':  val.id, 'dossier': val, 'value': value });
                            });
                        }
                        return dossiers;
                    }).catch(function() {
                        return [];
                    });

            }
            
            vm.onSelect = function(item, options) {
                vm.data.model.otherDossier = item.dossier
            }
            
            vm.deleteInput = function(event) {
            	vm.inputValue = "";
            	vm.data.model.otherDossier = undefined;
            }
            
            
        }],
        bindings: {
            key: '@?',
            data: '=',
            templateOptions: '='
        }
    })