angular
    .module('annexaApp')
    .component('annexaSelectMultiple', {
        templateUrl: './components/common/annexa-select-multiple/annexa-select-multiple.html',
        controller: ['$filter', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'Language', function($filter, $rootScope, globalModals, AnnexaFormlyFactory, Language) {
            var vm = this;

            if(vm.isReadOnly == "true") {
            	if(vm.canEdit == undefined) {
                    vm.canEdit = true;
                }
            } else if(vm.isReadOnly == "false") {
            	if(vm.canEdit == undefined) {
                    vm.canEdit = false;
                }
            }else {
            	if(vm.canEdit == undefined) {
                    vm.canEdit = true;
                }
            }
            
            vm.openModal = function() {
            	var modal = angular.copy(globalModals.selectMultipleCheckbox);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.model.modal_body.selectValues = {};
                modal.title = vm.label;
                
                
                vm.selectOptions = $linq(vm.selectOptions).orderBy("x => x." + vm.langCol, linq.caseInsensitiveComparer).toArray();
                var selectableOptions = [];
                _.forEach(vm.selectOptions, function (value, key) {
                	selectableOptions.push({ id: value.id, value: value[vm.langCol]});
                	modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                });
                _.forEach(vm.modelValue, function (value, key) {
                    modal.annexaFormly.model.modal_body.selectValues[value.id] = true;
                });
                
                modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = selectableOptions;
                
                var submitFunction = function () {
                	var selectedOptions = [];
                	if(modal.annexaFormly.model.modal_body.selectValues){
                    	var keys = Object.keys(modal.annexaFormly.model.modal_body.selectValues);
                        if(keys && keys.length > 0){
                            _.forEach(keys, function(key){
                                if(modal.annexaFormly.model.modal_body.selectValues[key]){
                                	var sel = $linq(vm.selectOptions).firstOrDefault(function (x) { return x.id == parseInt(key); });
                                	selectedOptions.push(sel); 
                                }
                            });
                        }
                    }
                	vm.saveModal(selectedOptions, vm.modelProperty);
                	vm.modelValue = selectedOptions;
                	
                	this.close();
                }
            	AnnexaFormlyFactory.showModal("selectMultipleCheckbox", modal, submitFunction, false);
            }
            
        }],
        bindings: {
            label: '@',
            modelValue: '=',
            modelProperty: '@',
            save: '&',
            saveModal: '=',
            required: '@',
            blur: '@',
            selectOptions: '=',
            langCol: '=',
            printFunction: '=',
            isReadOnly: '@',
            permissions: '=',
            profile: '=',
            childClass:'@'
        }
    })