angular
    .module('annexaApp')
    .component('annexaSelectThirds', {
        templateUrl: './components/common/annexa-select-thirds/annexa-select-thirds.html',
        controller: ['$filter', '$rootScope', 'NotificationFactory', 'ThirdFactory', 'GlobalDataFactory', 'CommonService', 'Language', '$state', function($filter, $rootScope, NotificationFactory, ThirdFactory, GlobalDataFactory, CommonService, Language, $state) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

			vm.selectedAll = false;
            
            if(!vm.title) {
                vm.title = 'global.literals.thirds';
            }

            if(!$rootScope.app.configuration.notification_representatives.value) {
                vm.thirds = $linq(vm.thirds).where("x => x.interested").toArray();
            }
            if($rootScope.app.configuration.notification_identification_reference.active){
            	vm.notification_identification_reference = $rootScope.app.configuration.notification_identification_reference;
            }
            
            var notificationTypes = angular.copy(GlobalDataFactory.notificationTypes);
            vm.notificationTelematicTypes = $linq(notificationTypes).where("x => x.addressType == 'TELEMATIC_EMAIL'").toArray();
            vm.notificationTelematicTypes.unshift({id:-1, language1:$filter('translate')('global.literals.notAssign'), language2:$filter('translate')('global.literals.notAssign'), language3:$filter('translate')('global.literals.notAssign')});
            vm.notificationTypeDefault = $linq(vm.notificationTelematicTypes).firstOrDefault(undefined, "x => x.id == -1");
            
            vm.selectNotificationType = function(item, third, defaultNotType) {
            	if(defaultNotType == undefined && item.addressType && item.addressType == 'TELEMATIC_EMAIL'){
            		vm.notificationTypeDefault = $linq(vm.notificationTelematicTypes).firstOrDefault(undefined, "x => x.id == -1");
            	}
            	if(item && item.identificationDestinataryType && item.identificationDestinataryPattern &&
            			third && third.third && third.third.identificationDocument) {
            		var patterns = item.identificationDestinataryPattern.split(",");
            		third.canReciveNotificationType = true;
            		switch (item.identificationDestinataryType) {
            			case "START":
            				third.canReciveNotificationType = false;
            				_.forEach(patterns, function (pattern) {
            					if(third.third.identificationDocument.startsWith(pattern.replace(/\s+/g, ''))) {
            						third.canReciveNotificationType = true;
            					}
            				});
            				break;
            			case "NOT_START":
            				_.forEach(patterns, function (pattern) {
            					if(third.third.identificationDocument.startsWith(pattern.replace(/\s+/g, ''))) {
            						third.canReciveNotificationType = false;
            					}
            				});
            				break;
            			case "FINISH":
            				third.canReciveNotificationType = false;
            				_.forEach(patterns, function (pattern) {
            					if(third.third.identificationDocument.endsWith(pattern.replace(/\s+/g, ''))) {
            						third.canReciveNotificationType = true;
            					}
            				});
            				break;
            			case "NOT_FINISH":
            				_.forEach(patterns, function (pattern) {
            					if(third.third.identificationDocument.endsWith(pattern.replace(/\s+/g, ''))) {
            						third.canReciveNotificationType = false;
            					}
            				});
            				break;
            			case "CONTAINS":
            				third.canReciveNotificationType = false;
            				_.forEach(patterns, function (pattern) {
            					if(third.third.identificationDocument.includes(pattern.replace(/\s+/g, ''))) {
            						third.canReciveNotificationType = true;
            					}
            				});
            				break;
            			case "NOT_CONTAINS":
            				_.forEach(patterns, function (pattern) {
            					if(third.third.identificationDocument.includes(pattern.replace(/\s+/g, ''))) {
            						third.canReciveNotificationType = false;
            					}
            				});
            				break;
            			default:
            				third.canReciveNotificationType = true;
            		}
            	} else {
            		third.canReciveNotificationType = true;
            	}
            }
 			
			_.forEach(vm.thirds, function (value, key) {
            	var notificationAddress = NotificationFactory.getNotificationAddress(value.third, vm.dossier);
            	if($state.current.name.startsWith('annexa.reg')){
            		if(value.thirdAddress && isNaN(value.thirdAddress) && !value.thirdAddress.deleted) {
            			notificationAddress = value.thirdAddress;
            		}else if(value.thirdAddress && !isNaN(value.thirdAddress) && value.third && value.third.addresses){
            			notificationAddress = $linq(value.third.addresses).firstOrDefault("x => x.id == "+value.thirdAddress);
            		}
                }
                vm.thirds[key].selected = ((value.selected === true || value.selected === false)?value.selected:false);
                vm.thirds[key].canReciveNotificationType = true;
                vm.thirds[key].label = CommonService.getThirdCompleteName(value.third);
                vm.thirds[key].labelHtml = CommonService.getThirdRelationType(value);
                vm.thirds[key].labelAddress = notificationAddress ? CommonService.getThirdAddress(notificationAddress) : '';
                var otherNotificationTypes = angular.copy($linq(GlobalDataFactory.notificationTypes).where("x => x.addressType == 'OTHER'").toArray());
                var notificationTypesCopy = angular.copy(GlobalDataFactory.notificationTypes);
                if($rootScope.app.configuration && $rootScope.app.configuration.create_with_consents && $rootScope.app.configuration.create_with_consents.value == true && ThirdFactory.getThirdAddresWithConsent(value.third, vm.dossier)) {
                    vm.thirds[key].addressesOrig = [angular.copy(notificationAddress)];
                    _.forEach(vm.thirds[key].addressesOrig, function (address) {
                        address.selected = true;
                        address.labelAddress = CommonService.getThirdAddress(address);
                        address.notificationTypes = $linq(notificationTypesCopy).where("x => x.addressType == '" + address.addressType + "'").toArray();
                        address.defaultNotificationType = undefined;
                        if(address.addressType == 'TELEMATIC_EMAIL'){
                        	address.defaultNotificationType = $linq(address.notificationTypes).firstOrDefault(undefined, "x => x.code == '"+vm.defaultNotificationType+"'");
                        }                        
                        address.selectedNotificationType = ((address.defaultNotificationType) ? address.defaultNotificationType : ((address.notificationTypes.length != 0)? address.notificationTypes[0] : undefined));
                        if(address.selectedNotificationType) {
                            vm.selectNotificationType(address.selectedNotificationType, vm.thirds[key]);
                        }
                    });
                } else {
                    vm.thirds[key].addressesOrig = angular.copy(vm.thirds[key].third.addresses);
                    _.forEach(vm.thirds[key].addressesOrig, function (address) {
                    	if (notificationAddress) {
                    		if(address.id == notificationAddress.id){
                    			address.selected = true;
                    		}else{
                    			address.selected = false;
                    		}
                    	}else if(address.default){
                            address.selected = true;
                        }else{
                            address.selected = false;
                        }
                        address.labelAddress = CommonService.getThirdAddress(address);
                        address.notificationTypes = $linq(notificationTypesCopy).where("x => x.addressType == '" + address.addressType + "'").toArray();
                        address.defaultNotificationType = undefined;
                        if(address.addressType == 'TELEMATIC_EMAIL'){
                        	address.defaultNotificationType = $linq(address.notificationTypes).firstOrDefault(undefined, "x => x.code == '"+vm.defaultNotificationType+"'");
                        }                        
                        address.selectedNotificationType = ((address.defaultNotificationType) ? address.defaultNotificationType : ((address.notificationTypes.length != 0)? address.notificationTypes[0] : undefined));
                        if(address.selectedNotificationType) {
                        	vm.selectNotificationType(address.selectedNotificationType, vm.thirds[key]);
                        }
                    });
                }
                var identReference = true;
                if(otherNotificationTypes != null && otherNotificationTypes.length > 0){
                	if(vm.notification_identification_reference && vm.notification_identification_reference.active){
                		var otherNotificationTypesTemp = [];
                		identReference = false;
                		_.forEach(vm.notification_identification_reference.notification_types, function(types){
                			if($linq(otherNotificationTypes).select("x=>x.code").contains(types.type)){
                				var third_reference_types = $linq(vm.thirds[key].third.identificationReferences).select("x => x.identificationReferenceType.code").toArray();
                				var reference_types = $linq(types.reference_types).intersect(third_reference_types).toArray();
                				if(reference_types.length > 0){
                					identReference = true;
                					otherNotificationTypesTemp = $linq(otherNotificationTypesTemp).union($linq(otherNotificationTypes).where("x=>x.code =='" + types.type +"'").toArray()).toArray();
                				}
                			}
                		});
                		otherNotificationTypes = otherNotificationTypesTemp;
                	}
                	if(identReference){
                		if(!vm.thirds[key].addressesOrig){
                    		vm.thirds[key].addressesOrig = [];
                    	}
                    	var addressOther = {
                    			addressType: "OTHER",
                    			labelAddress:"Altres",
                    			notificationTypes: otherNotificationTypes,
                    			selectedNotificationType: ((otherNotificationTypes.length != 0) ? otherNotificationTypes[0] : undefined),
                    			selected: false
                    			
                    	};
                    	vm.thirds[key].addressesOrig.push(addressOther);
                	}
                }
                if (vm.removeAddressesWithoutSelectedNotificationType && vm.removeAddressesWithoutSelectedNotificationType == true) {
                	//No se muestran los canales cuyo tipo no tiene definido tipo de notificacion.
                	vm.thirds[key].addressesOrig = $linq(vm.thirds[key].addressesOrig).where("x => x.selectedNotificationType != undefined").toArray();
                }
                _.forEach(vm.thirds[key].third.addresses, function (address) {
                	address.labelAddress = CommonService.getThirdAddress(address);
                });
                _.forEach(vm.thirds[key].addressesOrig, function (address) {
                	_.forEach(address.notificationTypes, function (notType) {
                		if(notType.addressTypeAlert) {
                    		var alertAddList = $linq(vm.thirds[key].third.addresses).where("x => x.addressType == '" + notType.addressTypeAlert + "'").toArray();
                    		if(alertAddList && alertAddList.length >= 1) {
                    			notType.alertAddresses = angular.copy(alertAddList);
                    			notType.alertAddresses.unshift({labelAddress: 'Sense avís', id: 0});
                    			notType.selectedAlertAddress = notType.alertAddresses[0];
                    		}
                		}
                	});
                });
            });

            vm.selectThird = function ($event, index) {
                var checkbox = $event.target;

                vm.thirds[index].selected = checkbox.checked;
                if(checkbox.checked) {
                	vm.selectNotificationType($linq(vm.thirds[index].addressesOrig).firstOrDefault({selectedNotificationType: undefined}, "x => x.selected == true").selectedNotificationType, vm.thirds[index]);
                }
            };

            vm.selectAddress = function (thirdId, addressId) {
                var third = $linq(vm.thirds).where("x => x.third.id =="+thirdId).toArray();
                if(third && third.length > 0){
                    _.forEach(third, function(value){
                        if(value.addressesOrig){
                            _.forEach(value.addressesOrig, function(value2){
                                if(value2.id == addressId){
                                    value2.selected = true;
                                    if(value2.selectedNotificationType){
                                    	vm.selectNotificationType(value2.selectedNotificationType, value);
                                    }
                                }else{
                                    value2.selected = false;
                                }
                            });
                        }
                    });
                }
            };
            
            vm.selectAll = function(){
				_.forEach(vm.thirds, function (value, key) {
            		vm.thirds[key].selected = ((vm.selectedAll)?true:false);
            	});
				$rootScope.$broadcast('annexa-select-thirds-selectedThirds', {});
			}
            
            vm.selectNotificationTelematicTypes = function(notificationTypeSelected){
            	if(notificationTypeSelected && notificationTypeSelected.id != -1){
            		_.forEach(vm.thirds, function (value, key) {
                		var consentActivate = false;
                		var notificationAddress = NotificationFactory.getNotificationAddress(value.third, vm.dossier);
                    	if($state.current.name.startsWith('annexa.reg')){
                    		if(value.thirdAddress && isNaN(value.thirdAddress) && !value.thirdAddress.deleted) {
                    			notificationAddress = value.thirdAddress;
                    		}else if(value.thirdAddress && !isNaN(value.thirdAddress) && value.third && value.third.addresses){
                    			notificationAddress = $linq(value.third.addresses).firstOrDefault("x => x.id == "+value.thirdAddress);
                    		}
                        }
                    	vm.thirds[key].selected = ((value.selected === true || value.selected === false)?value.selected:false);
                        vm.thirds[key].canReciveNotificationType = true;
                        vm.thirds[key].label = CommonService.getThirdCompleteName(value.third);
                        vm.thirds[key].labelHtml = CommonService.getThirdRelationType(value);
                        vm.thirds[key].labelAddress = notificationAddress ? CommonService.getThirdAddress(notificationAddress) : '';
                        var otherNotificationTypes = angular.copy($linq(GlobalDataFactory.notificationTypes).where("x => x.addressType == 'OTHER'").toArray());
                    	var notificationTypesCopy = angular.copy(GlobalDataFactory.notificationTypes);
                    	if($rootScope.app.configuration && $rootScope.app.configuration.create_with_consents && $rootScope.app.configuration.create_with_consents.value == true && ThirdFactory.getThirdAddresWithConsent(value.third, vm.dossier)) {
                            vm.thirds[key].addressesOrig = [angular.copy(notificationAddress)];
                            consentActivate = true;
                    	}else{
                    		 vm.thirds[key].addressesOrig = angular.copy(vm.thirds[key].third.addresses);
                    	}
                    	_.forEach(vm.thirds[key].addressesOrig, function (address) {
                    		if(consentActivate){
                    			address.selected = true;
                    		}else{
                    			if (notificationAddress) {
                            		if(address.id == notificationAddress.id){
                            			address.selected = true;
                            		}else{
                            			address.selected = false;
                            		}
                            	}else if(address.default){
                                    address.selected = true;
                                }else{
                                    address.selected = false;
                                }
                    		}
                            address.labelAddress = CommonService.getThirdAddress(address);
                            address.notificationTypes = $linq(notificationTypesCopy).where("x => x.addressType == '" + address.addressType + "'").toArray();
                            var defaultNotificationType = undefined;
                        	if(address.addressType == 'TELEMATIC_EMAIL'){
                            	defaultNotificationType = $linq(address.notificationTypes).firstOrDefault(undefined, "x => x.id == '"+notificationTypeSelected.id+"'");
                        	}
                        	address.selectedNotificationType = ((defaultNotificationType) ? defaultNotificationType : ((address.notificationTypes.length != 0)? address.notificationTypes[0] : undefined));
                            if(address.selectedNotificationType) {
                            	vm.selectNotificationType(address.selectedNotificationType, vm.thirds[key], true);
                            }
                        });
                    	var identReference = true;
                        if(otherNotificationTypes != null && otherNotificationTypes.length > 0){
                        	if(vm.notification_identification_reference && vm.notification_identification_reference.active){
                        		var otherNotificationTypesTemp = [];
                        		identReference = false;
                        		_.forEach(vm.notification_identification_reference.notification_types, function(types){
                        			if($linq(otherNotificationTypes).select("x=>x.code").contains(types.type)){
                        				var third_reference_types = $linq(vm.thirds[key].third.identificationReferences).select("x => x.identificationReferenceType.code").toArray();
                        				var reference_types = $linq(types.reference_types).intersect(third_reference_types).toArray();
                        				if(reference_types.length > 0){
                        					identReference = true;
                        					otherNotificationTypesTemp = $linq(otherNotificationTypesTemp).union($linq(otherNotificationTypes).where("x=>x.code =='" + types.type +"'").toArray()).toArray();
                        				}
                        			}
                        		});
                        		otherNotificationTypes = otherNotificationTypesTemp;
                        	}
                        	if(identReference){
                        		if(!vm.thirds[key].addressesOrig){
                            		vm.thirds[key].addressesOrig = [];
                            	}
                            	var addressOther = {
                            			addressType: "OTHER",
                            			labelAddress:"Altres",
                            			notificationTypes: otherNotificationTypes,
                            			selectedNotificationType: ((otherNotificationTypes.length != 0) ? otherNotificationTypes[0] : undefined),
                            			selected: false
                            			
                            	};
                            	vm.thirds[key].addressesOrig.push(addressOther);
                        	}
                        }
                        if (vm.removeAddressesWithoutSelectedNotificationType && vm.removeAddressesWithoutSelectedNotificationType == true) {
                        	//No se muestran los canales cuyo tipo no tiene definido tipo de notificacion.
                        	vm.thirds[key].addressesOrig = $linq(vm.thirds[key].addressesOrig).where("x => x.selectedNotificationType != undefined").toArray();
                        }
                        _.forEach(vm.thirds[key].third.addresses, function (address) {
                        	address.labelAddress = CommonService.getThirdAddress(address);
                        });
                        _.forEach(vm.thirds[key].addressesOrig, function (address) {
                        	_.forEach(address.notificationTypes, function (notType) {
                        		if(notType.addressTypeAlert) {
                            		var alertAddList = $linq(vm.thirds[key].third.addresses).where("x => x.addressType == '" + notType.addressTypeAlert + "'").toArray();
                            		if(alertAddList && alertAddList.length >= 1) {
                            			notType.alertAddresses = angular.copy(alertAddList);
                            			notType.alertAddresses.unshift({labelAddress: 'Sense avís', id: 0});
                            			notType.selectedAlertAddress = notType.alertAddresses[0];
                            		}
                        		}
                        	});
                        });
                	});
            	}            	
            }
        }],
        bindings: {
            title: '@?',
            thirds: '=',
            dossier: '=?',
            hideAddresses: '=',
            removeAddressesWithoutSelectedNotificationType: '=',
			thirdsEnabled: '=?',
			defaultNotificationType: '=?'
        }
    })