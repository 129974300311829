angular
    .module('annexaApp')
    .component('annexaSectionProposalType',{
        templateUrl: './components/common/annexa-section-proposal-type/annexa-section-proposal-type.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

        	vm.getProposalType = function(proposalType){
				if(proposalType && proposalType[vm.languageColumn]){
					return proposalType[vm.languageColumn];
				}else{
					return '';
				}
			}
            
			vm.getPresetSignCircuit = function(presetSignCircuit){
				if(presetSignCircuit && presetSignCircuit[vm.languageColumn]){
					return presetSignCircuit[vm.languageColumn];
				}else{
					return '';
				}
			}	                

			vm.saveNewProposalType = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var sectionProposalType = {
						proposalType: ((model.proposalType)?$linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined,"x => x.id == "+model.proposalType):undefined),
						presetSignCircuit: ((model.presetSignCircuit)?$linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined,"x => x.id == "+model.presetSignCircuit):undefined),
					}
					if(vm.isNew){
						vm.sectionProposalTypes.push(sectionProposalType);
						self.close();
					}else{
						sectionProposalType.section = ((vm.object && vm.object.id)?{id:vm.object.id}:undefined);
						RestService.insert('./api/section_proposal_type', sectionProposalType).then(function (data) {
							vm.sectionProposalTypes.push(data);
							$rootScope.$broadcast('saveListsSectionProposalTypesModal',{listToModify:angular.copy(vm.sectionProposalTypes), objectId:vm.object.id});
			        		self.close();
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
			            });
					}
				}
			}
			
			vm.deleteProposalType = function(item, index){
				if(vm.isNew){
					vm.sectionProposalTypes.splice(index, 1);
				}else{
					if(item && item.id){
						RestService.delete('./api/section_proposal_type/' + item.id).then(function (data) {
		        			vm.sectionProposalTypes.splice(index, 1);
		        			$rootScope.$broadcast('saveListsSectionProposalTypesModal',{listToModify:angular.copy(vm.sectionProposalTypes), objectId:vm.object.id});
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
			            });
					}else{
						vm.sectionProposalTypes.splice(index, 1);
					}
				}	
			}
			
			vm.newProposalType = function(){
				var modal = angular.copy(CommonAdminModals.sectionProposalTypeNew);
				modal.annexaFormly.options = {};
				AnnexaFormlyFactory.showModal('modalSectionProposalTypes', modal, vm.saveNewProposalType, false);
			}
			
			vm.saveEditProposalType = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var itemAux = angular.copy(this.temp.item);
					if(!vm.isNew){
						itemAux.proposalType = ((model.proposalType)?{id:model.proposalType}:undefined);
						itemAux.presetSignCircuit = ((model.presetSignCircuit)?{id:model.presetSignCircuit}:undefined);
					}else{
						self.temp.item.proposalType = ((model.proposalType)?$linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined,"x => x.id == "+model.proposalType):undefined);
						self.temp.item.presetSignCircuit = ((model.presetSignCircuit)?$linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined,"x => x.id == "+model.presetSignCircuit):undefined);
					}
					if(vm.isNew){
						self.close();
					}else{
						RestService.update('./api/section_proposal_type/' + itemAux.id, itemAux).then(function (data) {
							self.temp.item.proposalType = ((model.proposalType)?$linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined,"x => x.id == "+model.proposalType):undefined);
							self.temp.item.presetSignCircuit = ((model.presetSignCircuit)?$linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined,"x => x.id == "+model.presetSignCircuit):undefined);
							$rootScope.$broadcast('saveListsSectionProposalTypesModal',{listToModify:angular.copy(vm.sectionProposalTypes), objectId:vm.object.id});
							self.close();
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			            });
					}
				}
			}
			
			vm.editProposalType = function(item){
				var modal = angular.copy(CommonAdminModals.sectionProposalTypeNew);
				modal.annexaFormly.options = {};
				modal.temp = {item:item};
				modal.annexaFormly.model = { modal_body: {
					proposalType: ((item && item.proposalType && item.proposalType.id)?item.proposalType.id:undefined),
					presetSignCircuit: ((item && item.presetSignCircuit && item.presetSignCircuit.id)?item.presetSignCircuit.id:undefined)
				}}
				AnnexaFormlyFactory.showModal('modalSectionProposalTypesEdit', modal, vm.saveEditProposalType, false);
			}

        	this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.sectionProposalTypes){
					vm.sectionProposalTypes = [];
				}
            }
        }],
        bindings: {
            object: '=',
            isNew: '=?',
			sectionProposalTypes: '='
        }
    })