angular
    .module('annexaApp')
    .component('annexaBoxAddress', {
        templateUrl: './components/common/annexa-box-address/annexa-box-address.html',
        controller: ['AnnexaObjectBoxFactory', 'AnnexaEntityFactory', 'globalModals', 'ModalService', '$rootScope', 'GlobalDataFactory','Language', 'TerritoryFactory', '$filter', '$scope', 'AnnexaFormlyFactory', 'HelperService', function (AnnexaObjectBoxFactory, AnnexaEntityFactory, globalModals, ModalService, $rootScope, GlobalDataFactory, Language, TerritoryFactory,$filter, $scope, AnnexaFormlyFactory, HelperService) {
            var vm = this;

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASAddresses') {
                	var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                    
                    if(addedObjects && addedObjects.length > 0){
                    	vm.search.searchedValue = { id: id, object: addedObjects };
                        vm.search.completeAdd();
                    }
                    args.modal.close();
                }
            });

            this.$onInit = function() {
                vm.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.permissions, 'view_territory', 'view_territory', vm.isEdit);
                vm.languageColumn = Language.getActiveColumn();
                vm.territoryModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.territory'").toArray();
                
                vm.addressLanguage = 'address';
                if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
                	vm.addressLanguage = 'shortAddress';

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.territory.list.toptitle'; 
                }

                if(vm.search) {
                	vm.search.addedSelect = 'x => x.address.id';
                    vm.search.origin = 'territory';
                    vm.search.type = 'Address';
                    vm.search.customFilter = '{ "addresslanguage1": "@VAL@", "addresslanguage2": "@VAL@", "addresslanguage3": "@VAL@"}';
                    vm.search.processSearch = function (object) {
                    	return object[vm.addressLanguage+vm.languageColumn];
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                            var newContent = [];
                            if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push({address: JSOG.decode(obj)});
                        		});
                        	}else{
                    			newContent.push({address: JSOG.decode(vm.search.searchedValue.object)} );
                        	}
                            vm.search.searchedValue = { id: -1, value: '' };
                    		if(!vm.search.saveSearch) {
                    			_.forEach(newContent, function(obj){
                    				vm.content.push(obj);
                            	});
                            }else{
                                if(vm.search.saveSearch) {
                                    vm.search.saveSearch(newContent);
                                }
                            }
                        }
                    }

					vm.search.advancedSearch = function() {
                        var getAddedFunction = function() {
                        	var added = $linq(vm.content).select("x => x.id").toArray();
                        	return added;
                        }
                        var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
						var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
		                var advancedSearchModal = {
                            title: 'global.literals.advanced_search_registerEntry',
                            size: 'modal-lg',
                            filter: [
					    		{ id: 'addressType', type: 'select', order: 0, label: 'global.literals.addressType', dataType: 'LOCAL', data: GlobalDataFactory.addressTypes, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: false },
								{ id: 'streetType', type: 'select', order:1, label:'global.thirds.literals.streetType', dataType: 'LOCAL', data:streetTypes, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: false },
								{ id: 'street', type: 'text', order: 2, label: 'global.thirds.literals.streetName', callAux: true, userChoosen: false },
								{ id: 'numberAddress', type: 'text', order: 3, label: 'global.thirds.literals.number', callAux: true, userChoosen: false }, 
		        				{ id: 'country', type: 'select', order: 4, label: 'global.thirds.literals.country', dataType: 'LOCAL', data: countries, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true },
								{ id: 'state', type: 'text', order: 5, label: 'global.thirds.literals.state', callAux: true, userChoosen: true }, 
		        				{ id: 'city', type: 'text', order: 6, label: 'global.literals.city', callAux: true, userChoosen: true }, 
		        				{ id: 'postalCode', type: 'text', order: 7, label: 'global.thirds.literals.postalCode', callAux: true, userChoosen: true }, 
		        				{ id: 'pseudoVia', type: 'text', order: 8, label: 'global.thirds.literals.pseudoVia', callAux: true, userChoosen: true }, 
		        				{ id: 'numberAddress2', type: 'text', order: 9, label: 'global.thirds.literals.number2', callAux: true, userChoosen: true }, 
		        				{ id: 'bis', type: 'text', order: 10, label: 'global.thirds.literals.bis', callAux: true, userChoosen: true }, 
		        				{ id: 'bis2', type: 'text', order: 11, label: 'global.thirds.literals.bis2', callAux: true, userChoosen: true }, 
		        				{ id: 'kilometer', type: 'text', order: 12, label: 'global.thirds.literals.kilometer', callAux: true, userChoosen: true }, 
		        				{ id: 'block', type: 'text', order: 13, label: 'global.thirds.literals.block', callAux: true, userChoosen: true }, 
		        				{ id: 'portal', type: 'text', order: 14, label: 'global.thirds.literals.portal', callAux: true, userChoosen: true }, 
		        				{ id: 'stair', type: 'text', order: 15, label: 'global.thirds.literals.stair', callAux: true, userChoosen: true }, 
		        				{ id: 'floor', type: 'text', order: 16, label: 'global.thirds.literals.floor', callAux: true, userChoosen: true }, 
		        				{ id: 'door', type: 'text', order: 17, label: 'global.thirds.literals.door', callAux: true, userChoosen: true }, 
		        				{ id: 'cadastralReference', type: 'text', order: 18, label: 'global.territory.list.cadastral_reference', callAux: true, userChoosen: true }, 
		        				{ id: 'coordinateX', type: 'text', order: 19, label: 'global.territory.list.coordinateX', callAux: true, userChoosen: true }, 
		        				{ id: 'coordinateY', type: 'text', order: 20, label: 'global.territory.list.coordinateY', callAux: true, userChoosen: true }, 
		        				{ id: 'address', type: 'text', order: 21, label: 'global.territory.list.address', callAux: true, userChoosen: true }
                            ],
                            tableDefinition: {
                                id: 'tableAdvancedSearchAddress',
                                origin: 'territory',
                                objectType: 'Address',
                                sortName: 'address',
                                sort: [[1, 'asc']],
                                columns: [
                                    { id: vm.addressLanguage+Language.getActiveColumn(), title: $filter('translate')('global.territory.list.address')},
						        	{ id: 'addressType', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', Language.getActiveColumn()) }
                                ],
                                noLoading: true,
                                skipFirstSearch: true,
                                callAuxToAdd: { added: getAddedFunction(),  isAdvanced:true, active:1}
                            },
                            multiple: true
                        }
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASAddresses', advancedSearchModal);
                    }
                }

                if(vm.new) {
                	vm.new.newFunc = function() {
                		var getLanguage  = function(address){
                        	return address[vm.addressLanguage+vm.languageColumn];
                		}
                		var preCloseModal = function(address) {
                            vm.search.searchedValue = {
                                id: address.id, value: address[vm.addressLanguage+vm.languageColumn],
                                object: {
                                	id: address.id,
                            		addresslanguage1:getLanguage(address),
                            		addresslanguage2:getLanguage(address),
                            		addresslanguage3:getLanguage(address)
                                }
                            };
                            vm.search.completeAdd();
                        }
                		
                		var newModal =  TerritoryFactory.newAddress(undefined, preCloseModal, true);
                    };
                    
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@'
        }
    })
    .component('annexaBoxAddressRender', {
        templateUrl: './components/common/annexa-box-address/annexa-box-address-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'HelperService','Language', function(DialogsFactory, $rootScope, HelperService,Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addressLanguage = 'address';
            if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
            	vm.addressLanguage = 'shortAddress';

            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteAddressBox')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxAddressDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })