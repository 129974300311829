angular
    .module('annexaApp')
	.factory('injectCSS', ['$q', '$http', '$rootScope', function($q, $http, $rootScope){
	  	var injectCSS = {};
	  
	  	var createLink = function(id, url) {
	    	var link = document.createElement('link');
	    	link.id = id;
	    	link.rel = "stylesheet";
	    	link.type = "text/css";
	    	link.href = url;
			return link;
	  	}
	  
	  	var checkLoaded = function (url, deferred, tries) {
	    	for (var i in document.styleSheets) {
		      	var href = document.styleSheets[i].href || "";
		      	if (href.endsWith(url)) {
		        	deferred.resolve();
		        	return;
		      	}
	    	}
	    	tries++;
			if(tries > 10){
				deferred.resolve();
	        	return;
			}else{
	    		setTimeout(function(){checkLoaded(url, deferred, tries);}, 50);
			} 
	  	};
	    
	  	injectCSS.setCSS = function(){
	    	var tries = 0;
	      	var deferred = $q.defer();
	      	var link;
	    	var	id = 'annexaCssInjection'
			var	url = './extResources/'+$rootScope.tenantImagesId+'/css/annexa.css';
			var urlToSearch = '/extResources/'+$rootScope.tenantImagesId+'/css/annexa.css'
	    	if(!angular.element('link#' + id).length) {
	      		link = createLink(id, url);
	      		link.onload = deferred.resolve;
	      		angular.element('head').append(link);
	    	}
	    	checkLoaded(urlToSearch, deferred, tries);
	    
	    	return deferred.promise;
	  	};
	  
		return injectCSS;
}])