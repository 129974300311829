/**
 * Created by osirvent on 21/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaPageList', {
        transclude: true,
        templateUrl: './components/common/annexa-page-list/annexa-page-list.html',
        controller: [function () {
            var vm = this;

            if(!vm.alerts) {
                vm.alerts = [];
            }

            vm.closeAlert = function(index) {
                vm.alerts.splice(index, 1);
            }
        }],
        bindings: {
            list: '=',
            tableDefinition: '=',
            documentsByType: '=',
            showViewModes: '=',
            showList: '=',
            showFolders: '=',
            viewModes: '=',
            alerts: '=?',
            legend: '=?',
            legendOnTop: '=?'
        }
    })
	.component('annexaPageListIdentifier', {
        transclude: true,
        templateUrl: './components/common/annexa-page-list/annexa-page-list-identifier.html',
        controller: [function () {
            var vm = this;

            if(!vm.alerts) {
                vm.alerts = [];
            }

            vm.closeAlert = function(index) {
                vm.alerts.splice(index, 1);
            }
        }],
        bindings: {
            tableDefinition: '=',
            alerts: '=?',
            legend: '=?',
            legendOnTop: '=?'
        }
    });