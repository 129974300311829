/**
 * Created by osirvent on 09/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaLabelValueForm', {
        transclude: true,
        templateUrl: './components/common/annexa-label-value-form/annexa-label-value-form.html',
        controller: [function () {
            var vm = this;

            this.$onInit = function () {
                if(!vm.type) {
                    vm.type = 'text';
                }

                if(!vm.rowClass) {
                    vm.rowClass = ' m-b-xs';
                }

                if(!vm.valueProperty) {
                    vm.valueProperty = 'filename';
                }

                if(vm.naValue == undefined) {
                    vm.naValue = 'global.literals.n_a';
                }

                if(!vm.valueClass) {
                    vm.valueClass = 'col-lg-12';
                }

                if(!vm.transcludeClass) {
                    vm.transcludeClass = 'col-lg-12';
                }
            }
        }],
        bindings: {
            label: '@',
            value: '=',
            type: '@?',
            rowClass: '@?',
            labelStrong: '<',
            valueProperty: '@?',
            deleteFunc: '=?',
            origin: '=?',
            naValue: '@?',
            valueClass: '@?',
            transcludeClass: '@'
        }
    })