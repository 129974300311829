angular
    .module('annexaApp')
    .component('annexaTooltipThirds',{
        templateUrl: './components/common/annexa-tooltip-thirds/annexa-tooltip-thirds.html',
        controller:['$state', '$rootScope', '$sce', 'AnnexaPermissionsFactory', function ($state, $rootScope, $sce, AnnexaPermissionsFactory) {
            var vm = this;

            vm.tooltip = '';
            vm.showTooltip = false;
            vm.principalThird = '';
            var numThirds = 0;

            this.$onInit = function () {
            	if(vm.thirds && vm.thirds.length > 1) {
            		vm.showTooltip = true;
            		_.forEach(vm.thirds, function(third){
	               		 if(third.principal || vm.showFirst == "true") {
	               			if(third.third.thirdType != 'PHISICAL') {
	               				vm.principalThird = third.third.corporateName;
	                        } else {
		               			vm.principalThird = third.third.name + ((third.third.surname1Particles) ? ' ' + third.third.surname1Particles : '') + ' ' + third.third.surename1;
		                		if(third.third.surename2){
		                			vm.principalThird = vm.principalThird + ((third.third.surname2Particles) ? ' ' + third.third.surname2Particles : '') + ' ' + third.third.surename2 ;
		                        }
	                        }
	               			vm.showFirst = false;
	               		 } else {
	               			if(numThirds > 0) {
	               				vm.tooltip = vm.tooltip + ' | ';
	               			}
	               			if(third.third.thirdType != 'PHISICAL') {
	               				vm.tooltip = vm.tooltip + third.third.corporateName;
	                        } else {
		               			vm.tooltip = vm.tooltip + third.third.name + ((third.third.surname1Particles) ? ' ' + third.third.surname1Particles : '') + ' ' + third.third.surename1;
		                		if(third.third.surename2){
		                			vm.tooltip = vm.tooltip + ((third.third.surname2Particles) ? ' ' + third.third.surname2Particles : '') + ' ' + third.third.surename2 ;
		                        }
	                        }
	               			numThirds++;
	               		 }
	               	});
            	} else if(vm.thirds && vm.thirds[0] && vm.thirds[0].third) {
            		if(vm.thirds[0].third.thirdType != 'PHISICAL') {
           				vm.principalThird = vm.thirds[0].third.corporateName;
                    } else {
                    	vm.principalThird = vm.thirds[0].third.name + ((vm.thirds[0].third.surname1Particles) ? ' ' + vm.thirds[0].third.surname1Particles : '') + ' ' + vm.thirds[0].third.surename1;
                		if(vm.thirds[0].third.surename2){
                			vm.principalThird = vm.principalThird  + ((vm.thirds[0].third.surname2Particles) ? ' ' + vm.thirds[0].third.surname2Particles : '') + ' ' + vm.thirds[0].third.surename2 ;
                        }
                    }
            	}
            }
        }],
        bindings: {
            thirds: '=',
            showFirst: '@'
        }
    })