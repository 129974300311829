angular
    .module('annexaApp')
    .component('annexaViewTransaction', {
        templateUrl: './components/common/annexa-view-transaction/annexa-view-transaction.html',
        controller: ['$filter', '$rootScope', '$http', '$state', 'Language',function($filter, $rootScope , $http, $state, Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.relationTypeRender = function(data, object) {
            	if(object && object.paperBin) {
                    return $filter('translate')('global.literals.paperBin') + ' (' + $filter('translate')('global.tram.documentRelationType.' + data) + ')';
            	} else {
                    return $filter('translate')('global.tram.documentRelationType.' + data);
            	}
            };
            
            vm.dateRender = function(data) {
            	if(data) {
            		return $filter('date')(new Date(data), 'dd/MM/yyyy - HH:mm');
            	} else {
            		return '';
            	}
            }
            
        }],
        bindings: {
            dossierTransactions: '=',
            data: '@?'
        }
    })