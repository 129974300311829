angular
    .module('annexaApp')
    .component('annexaBoxDocumentationToProvide',{
        templateUrl: './components/common/annexa-box-documentation-to-provide/annexa-box-documentation-to-provide.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'CustomFieldFactory', 'DccumentsFactory', 'TramNewFactory', 
        function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, CustomFieldFactory, DccumentsFactory, TramNewFactory) {
            var vm = this;
			vm.showContent = false;
			this.$onInit = function () {
				if(vm.box && !vm.box.boxTitle) {
	    	        vm.box.boxTitle = 'global.tram.literals.documentationToProvide';
	        	}
				if(vm.box && !vm.box.emptyText) {
	    	        vm.box.emptyText = 'global.tram.literals.documentationToProvideEmptyText';
	        	}
				
				if(vm.box && vm.box.content && vm.box.content.show){
					vm.showContent = true;
				}
				if(vm.box){
					if(vm.box && !vm.box.new){
						vm.box.new = {}	
					}
		       		vm.box.new.showMassiveInteroperable = false;
	                if(vm.box && vm.box.content && vm.box.content.edit){
						if(vm.box && vm.box.content && vm.box.content.globals && vm.box.content.globals.length > 0){
							_.forEach(vm.box.content.globals, function(global){
								if(!vm.box.new.showMassiveInteroperable && global.interoperability && global.status === 'PENDING'){
									vm.box.new.showMassiveInteroperable = true;
								}
							});
						}
						if(vm.box && vm.box.content && vm.box.content.thirds && vm.box.content.thirds.length > 0){
							_.forEach(vm.box.content.thirds, function(third){
								if(!vm.box.new.showMassiveInteroperable){
									_.forEach(third.documents, function(global){
										if(!vm.box.new.showMassiveInteroperable && global.interoperability && global.status === 'PENDING'){
											vm.box.new.showMassiveInteroperable = true;
										}
									});
								}
							});				
						}
					}
	                vm.box.new.newMassiveInteroperable = function(){
						DccumentsFactory.openMassiveInteroperableModal(vm.box.content.globals, vm.box.content.thirds, 'EXPED', ((vm.box.object && vm.box.object.id)?vm.box.object:undefined), TramNewFactory);
	                }
	            }

				vm.calculateClass = function(item){
					if(item.status === 'ADDED'){
						item.class = "fa fa-circle text-success text-lg fa-stack-1x";
					}else if(item.status === 'REQUIRED'){
						item.class = "fa fa-adjust text-blue text-lg fa-stack-1x";
					}else if(item.status === 'NO_ADDED'){
						if(item.id && item.required){
							item.class = "fa fa-circle text-danger text-lg fa-stack-1x";
						}else{
							item.class = "fa fa-circle text-blue text-lg fa-stack-1x";
						}
					}else{
						item.class = "fa fa-circle-o text-blue text-lg fa-stack-1x";
					}
				}
				
				var initializeDocProv = function(global){
					vm.calculateClass(global);
					if(!global.status){
						global.status = 'PENDING';
					}
					if(global.id && global.required){
						global.required = true;
					}else{
						global.required = false;
					}
					if(global.id && global.original){
						global.original = true;
					}else{
						global.original = false;
					}
				}
			
				$scope.$on('annexaBoxObjectDossierDocumentationToProvideMassiveUpdated', function(event, args) {
		            if(args.dtpr) {
						if(TramNewFactory.dossierDocumentationToProvide.dossierThirds){
							TramNewFactory.dossierDocumentationToProvide.dossierThirds.length = 0;
						}
						if(TramNewFactory.dossierDocumentationToProvide.dossierGlobals){
							TramNewFactory.dossierDocumentationToProvide.dossierGlobals.length = 0;
						}
						if(args.dtpr.dossierGlobals){
							_.forEach(args.dtpr.dossierGlobals, function(dg){
								TramNewFactory.dossierDocumentationToProvide.dossierGlobals.push(dg);	
							});
							
						}
						if(args.dtpr.dossierThirds){
							_.forEach(args.dtpr.dossierThirds, function(dg){
								TramNewFactory.dossierDocumentationToProvide.dossierThirds.push(dg);	
							});
						}

						vm.box.new.showMassiveInteroperable = false;
						if(vm.box && vm.box.content && vm.box.content.globals && vm.box.content.globals.length > 0){
							vm.showContent = true;
							_.forEach(vm.box.content.globals, function(global){
								if(vm.box.content.edit && !vm.box.new.showMassiveInteroperable && global.interoperability && global.status === 'PENDING'){
									vm.box.new.showMassiveInteroperable = true;
								}
								initializeDocProv(global);
							});
						}
						if(vm.box && vm.box.content && vm.box.content.thirds && vm.box.content.thirds.length > 0){
							vm.showContent = true;		
							_.forEach(vm.box.content.thirds, function(third){
								if(third.documents){
									_.forEach(third.documents, function(global){
										if(vm.box.content.edit && !vm.box.new.showMassiveInteroperable && global.interoperability && global.status === 'PENDING'){
											vm.box.new.showMassiveInteroperable = true;
										}
										initializeDocProv(global);
									});
								}
							});				
						}
					}
		        }); 	
            }
        }],
        bindings: {
            box: '='
        }
    })
    .component('annexaBoxDocumentationToProvideRender', {
        templateUrl: './components/common/annexa-box-documentation-to-provide/annexa-box-documentation-to-provide-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['$q','SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'TramNewFactory', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'AnnexaPermissionsFactory', 'RestService', 'DccumentsFactory', 'AnnexaEntityFactory', 
        function($q,SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, TramNewFactory,GlobalDataFactory, globalModals, AnnexaModalFactory, AnnexaPermissionsFactory, RestService,DccumentsFactory,AnnexaEntityFactory) {
        	var vm = this;
			vm.languageColumn = Language.getActiveColumn();
			vm.showContent = false;
			vm.editBox = true;
			vm.classDocType = "col-sm-2";
			vm.classDescription = "col-sm-2";
			vm.classStatus = "col-sm-1";
			vm.seeDocument = function(item){
				if(item && item.document && item.document.id){
					window.open($state.href('annexa.doc.documents.view', { code: item.document.id }), '_blank');
				}else{
					var a = 0;
				}
			}
			
			var markAddedDossier = function(item, updateFunction){
				var showDossierModel = function(documents, documentsThird, dossierThird){
					var modalExp = angular.copy(globalModals.documentationToProvideAddDocument_Dossier);	
					modalExp.annexaFormly.options = {};
					modalExp.annexaFormly.model = {modal_body:{documentationToProvide:angular.copy(item)}};
					var selectedDocumentType = ((item && item.documentType && item.documentType.id)?{id:item.documentType.id}:undefined);
					var searchDocument = function(val) {
		                var filter = {
		                    name: val.val,
							type: selectedDocumentType
		                };
		                var additional_filter = { 
							loggedUser:$rootScope.LoggedUser.id,
							langColumn:vm.languageColumn,
							related: true,
							documentBoxNormalSearch:true
						};
		                return RestService.filterData('doc', 'Document', filter, additional_filter, 0, 10, '', 3,'box')
		                    .then(function(data) {
		                        var response = [];
		                        _.forEach(data.data.content, function (value) {
		                            response.push({ id: value.id, value: value.name });
		                        });
		                        response = new SortedArray(response, 'value').sort();
		                        return response;
		                    });
		            };
		
					var advancedSearchDocument = function() {
		                var docStatusRender = function(data, type, full, meta) {
		                     var content = '';
		                     if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
		                         var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");
		                         if(status) {
		                             content += '<div class="text-center">';
		                             content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
		                             content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
		                             content += '    </span>';
		                             content += '</div>';
		                         }
		                     }
		                     return content;
		                 }
						 var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
			             var advancedSearchModal = {  
							title: 'global.literals.advanced_search_documents',
		                    size: 'modal-lg',
		                    filter: [
		                        { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
		                        { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
		                        { id: 'csv', type: 'text', order: 2, label: 'global.documents.new.csv' },
		                        { id: 'internalDoc', type: 'checkbox', order: 3, label: 'global.documents.internalDocument', callAux: true, model: true },
		                        { id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: true },
		                        { id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
		                        { id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: true },
		                        { id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: true },
		                        { id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: true },
		                        { id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: true },
		                        { id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: true },
		                        { id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: true },
                                { id: 'related', type: 'checkbox', order: 9, label: 'global.literals.showRelateds', callAux: true, preFilter:true, negated:false}
		                    ],
							tableDefinition: {
		                        id: 'tableAdvancedSearchDocuments',
		                        origin: 'doc',
		                        objectType: 'Document',
		                        sortName: 'name',
		                        sort: [[3, 'asc']],
		                        columns: [
		                        	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
		                            { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
		                            { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
		                            { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
		                            { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
		                            { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
		                        ], 
		                        noLoading: true,
		                        skipFirstSearch: true,
 								callToAdd: {type: selectedDocumentType },
		                        callAuxToAdd: { includeRegisterEntry: false }
		                    },
		                    multiple: false
						 }
			             AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalDocumentationDocuments', advancedSearchModal);
		            };
					var addCreatedNewDocument = function(){
	                    var preCloseNewModal = function(document) {
	                       if(document){
								modalExp.annexaFormly.model.modal_body.createDocument = ((document)?document.name:undefined);
								modalExp.annexaFormly.model.modal_body.createDocumentId = ((document)?document.id:undefined);	
								var paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createDocument'");
								if(paramsField && paramsField.templateOptions){
									paramsField.templateOptions.label = modalExp.annexaFormly.model.modal_body.createDocument;
								}	                    	
		                    }
	                    }
						var selectedDT = undefined;
						if(selectedDocumentType && selectedDocumentType.id){
							selectedDT = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id =="+selectedDocumentType.id);
						}
						var docModal = undefined;
						if(modalExp.annexaFormly && modalExp.annexaFormly.model && modalExp.annexaFormly.model.modal_body && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded.id){
                    		var extra = {
                            	transactionType: modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded.transactionType,
                            	processProfiles: modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded.processProfiles
                            }
                    		if(DccumentsFactory.sectionsUser && DccumentsFactory.sectionsUser.length > 0){
                    			docModal = DccumentsFactory.getOpenNewDocumentModal(undefined, preCloseNewModal, 'EXPED', undefined, undefined, ((vm.box && vm.box.object && vm.box.object)?vm.box.object.archiveClassification:undefined), selectedDT, extra);
                    		}else{
                    			DialogsFactory.error($filter('translate')('global.errors.noUserSection'), $filter('translate')('DIALOGS_ERROR_MSG'));
                    		}
						}else{
							if(DccumentsFactory.sectionsUser && DccumentsFactory.sectionsUser.length > 0){
								docModal = DccumentsFactory.getOpenNewDocumentModal(undefined, preCloseNewModal, 'EXPED', undefined, undefined, undefined,selectedDT);
							}else{
								DialogsFactory.error($filter('translate')('global.errors.noUserSection'), $filter('translate')('DIALOGS_ERROR_MSG'));
							}
						}
						if(docModal && docModal.annexaFormly && docModal.annexaFormly.fields && docModal.annexaFormly.fields.length > 0 && docModal.annexaFormly.fields[0].fieldGroup){
							var docTypeField = $linq(docModal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeSelect'");
				            if(docTypeField){
								docTypeField.templateOptions.disabled = true;
							}
							AnnexaFormlyFactory.showModal('modalDocumentNew', docModal, DccumentsFactory.saveNewDocument, false, undefined);
						}
					}
					var addCreatedNewInteroperableDocument = function(){
	                    var commitModal = function(documents) { 
							if(documents && documents.length > 0){
								if(documents[0]){ 
									modalExp.annexaFormly.model.modal_body.createInteroperableDocument = ((documents[0])?documents[0].name:undefined);
									modalExp.annexaFormly.model.modal_body.createInteroperableDocumentId = ((documents[0])?documents[0].id:undefined);	
									var paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createInteroperableDocument'");
									if(paramsField && paramsField.templateOptions){
										paramsField.templateOptions.label = modalExp.annexaFormly.model.modal_body.createInteroperableDocument;
									}	                    	
		                    	}
							}
	                    }
						if(modalExp.annexaFormly && modalExp.annexaFormly.model && modalExp.annexaFormly.model.modal_body && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded.id){
							var selectedDT = undefined;
							if(selectedDocumentType && selectedDocumentType.id){
								selectedDT = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id =="+selectedDocumentType.id);
							}
							var trans = angular.copy(modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded);
							if(vm.box && vm.box.object && vm.box.object){
								trans.dossier = angular.copy(vm.box.object);
							}
							if(dossierThird){
								DccumentsFactory.openNewInteroperbleModal({}, [dossierThird], commitModal, 'EXPED', trans, angular.copy(item), true, ((selectedDT)?[selectedDT]:undefined), ((selectedDT)?true:undefined));
							}else{
								TramNewFactory.getDossierThirds(((trans.dossier)?trans.dossier.id:undefined)).then(function (data) {
									DccumentsFactory.openNewInteroperbleModal({}, ((data)?JSOG.decode(data):[]), commitModal, 'EXPED', trans, angular.copy(item), true, ((selectedDT)?[selectedDT]:undefined), ((selectedDT)?true:undefined));
								}).catch(function (data) {
		                    		
				           		});
							}
						}
	                }
					var addCreatedNewDossierTemplateDocument = function(){
			    		var commitModalTemplate = function(documents) {
							if(documents && documents.length > 0){
								if(documents[0] && documents[0].document){
									modalExp.annexaFormly.model.modal_body.createDossierTemplateDocument = ((documents[0].document)?documents[0].document.name:undefined);
									modalExp.annexaFormly.model.modal_body.createDossierTemplateDocumentId = ((documents[0].document)?documents[0].document.id:undefined);	
									var paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createDossierTemplateDocument'");
									if(paramsField && paramsField.templateOptions){
										paramsField.templateOptions.label = modalExp.annexaFormly.model.modal_body.createDossierTemplateDocument;
									}	                    	
		                    	}
							}
	                    }
						if(modalExp.annexaFormly && modalExp.annexaFormly.model && modalExp.annexaFormly.model.modal_body && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded.id){
							var trans = angular.copy(modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded);
							if(vm.box && vm.box.object && vm.box.object){
								trans.dossier = angular.copy(vm.box.object);
							}
							var bdef = {documents:{dossier:trans.dossier, saveDocumentationToProvide:commitModalTemplate}};
							var selectedDT = undefined;
							if(selectedDocumentType && selectedDocumentType.id){
								selectedDT = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id =="+selectedDocumentType.id);
							}
							var tramTemplates = [];
							if(selectedDocumentType && selectedDocumentType.id){
								tramTemplates = $linq(TramNewFactory.TramTemplates).where("x => ((x.documentType && x.documentType.id == "+selectedDocumentType.id+") || !x.documentType)").toArray();
							}
                    		if(item.scopeApplication === 'THIRD'){
								AnnexaEntityFactory.showNewDocumentFromTemplateModal(trans, new SortedArray(tramTemplates, vm.languageColumn).sort(), [selectedDT], bdef, undefined, undefined, ((dossierThird)?[dossierThird]:undefined));
							}else{
								AnnexaEntityFactory.showNewDocumentFromTemplateModal(trans, new SortedArray(tramTemplates, vm.languageColumn).sort(), [selectedDT], bdef);
							}
						}
                    }
					var addCreatedNewDigitalizationDocument = function(){
						var preCloseDigitalizationModal = function() {
	                       if(this.wizard && this.wizard.steps && this.wizard.steps[this.wizard.steps.length - 1] && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields.length > 0 && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents.length && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents[0] && this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents[0].docData){
								var document = this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents[0].docData;
								modalExp.annexaFormly.model.modal_body.createDigitalizationDocument = ((document)?document.name:undefined);
								modalExp.annexaFormly.model.modal_body.createDigitalizationDocumentId = ((document)?document.id:undefined);	
								var paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createDigitalizationDocument'");
								if(paramsField && paramsField.templateOptions){
									paramsField.templateOptions.label = modalExp.annexaFormly.model.modal_body.createDigitalizationDocument;
								}	                    	
		                    }
	                    }
						var selectedDT = undefined;
						if(selectedDocumentType && selectedDocumentType.id){
							selectedDT = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id =="+selectedDocumentType.id);
						}
						if(modalExp.annexaFormly && modalExp.annexaFormly.model && modalExp.annexaFormly.model.modal_body && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded && modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded.id){
							var trans = angular.copy(modalExp.annexaFormly.model.modal_body.dossierTransactionToAdded);
							if(vm.box && vm.box.object && vm.box.object){
								trans.dossier = angular.copy(vm.box.object);
							}
                    		DccumentsFactory.openOneDocumentModalScan(preCloseDigitalizationModal, {module:'EXPED'}, ((vm.box && vm.box.object && vm.box.object)?vm.box.object.archiveClassification:undefined), JSOG.encode(trans), selectedDT,true);
        	            }else{
                            DccumentsFactory.openOneDocumentModalScan(preCloseDigitalizationModal, {module:'EXPED'}, undefined, undefined, selectedDT,true);
						}
                    }
					var addDocument_toDossier = function(){
						var addDocToDoModal = this;
						if(addDocToDoModal && addDocToDoModal.annexaFormly && addDocToDoModal.annexaFormly.model && addDocToDoModal.annexaFormly.model.modal_body){
							var model = addDocToDoModal.annexaFormly.model.modal_body;
							if(model.typeToEnter && model.typeToEnter === 'EXIST'){
								if(model.existDocument && model.existDocument.id){
									updateFunction(item,{id:model.existDocument.id});
									modalExp.close();
								}
							}else if(model.typeToEnter && model.typeToEnter === 'NEW'){
								if(model.newDocumentType && model.newDocumentType === 'EXIST'){
									if(model.loadDocument && model.loadDocument.id){
										updateFunction(item,{id:model.loadDocument.id},((model.dossierTransactionToAdded && model.dossierTransactionToAdded.id)?model.dossierTransactionToAdded.id:undefined),((dossierThird && dossierThird.id)?dossierThird.id:undefined));
										modalExp.close();
									}else if(model.loadDocumentId){
										updateFunction(item,{id:model.loadDocumentId});
										modalExp.close();
									}
								}else if(model.newDocumentType && model.newDocumentType === 'NEW'){
									if(model.createDocumentId){
										updateFunction(item,{id:model.createDocumentId},((model.dossierTransactionToAdded && model.dossierTransactionToAdded.id)?model.dossierTransactionToAdded.id:undefined),((dossierThird && dossierThird.id)?dossierThird.id:undefined));
										modalExp.close();
									}
								}else if(model.newDocumentType && model.newDocumentType === 'INTEROPERABILITY'){
									if(model.createInteroperableDocumentId){
										updateFunction(item,{id:model.createInteroperableDocumentId},((model.dossierTransactionToAdded && model.dossierTransactionToAdded.id)?model.dossierTransactionToAdded.id:undefined),((dossierThird && dossierThird.id)?dossierThird.id:undefined));
										modalExp.close();
									}
								}else if(model.newDocumentType && model.newDocumentType === 'THIRD'){
									if(model.existThirdDocument && model.existThirdDocument.id){
										updateFunction(item,{id:model.existThirdDocument.id});
										modalExp.close();
									}
								}else if(model.newDocumentType && model.newDocumentType === 'DOSSIERTEMPLATE'){
									if(model.createDossierTemplateDocumentId){
										updateFunction(item,{id:model.createDossierTemplateDocumentId},((model.dossierTransactionToAdded && model.dossierTransactionToAdded.id)?model.dossierTransactionToAdded.id:undefined),((dossierThird && dossierThird.id)?dossierThird.id:undefined));
										modalExp.close();
									}
								}else if(model.newDocumentType && model.newDocumentType === 'DIGITALIZED'){
									if(model.createDigitalizationDocumentId){
										updateFunction(item,{id:model.createDigitalizationDocumentId},((model.dossierTransactionToAdded && model.dossierTransactionToAdded.id)?model.dossierTransactionToAdded.id:undefined),((dossierThird && dossierThird.id)?dossierThird.id:undefined));
										modalExp.close();
									}
								}else{
									modalExp.alerts.push({ msg: $filter('translate')('global.errors.errorCreateDocument') });
								}
							}else{
								modalExp.alerts.push({ msg: $filter('translate')('global.errors.errorCreateDocument') });
							}
						}else{
								modalExp.alerts.push({ msg: $filter('translate')('global.errors.errorCreateDocument') });
						}
					}
					$scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
		                if(args.id == 'modalDocumentationDocuments') {
		                    var object = undefined;
		                    _.forEach(args.selected, function (item) {
		                		if(!object){
									object = item;
		                    	}
		                    });
		                    if(object){
								modalExp.annexaFormly.model.modal_body.loadDocument = ((object)?object.name:undefined);
								modalExp.annexaFormly.model.modal_body.loadDocumentId = ((object)?object.id:undefined);		                    	
		                    }
		                    args.modal.close();
		                }
		            });

					if(modalExp && modalExp.annexaFormly && modalExp.annexaFormly.fields && modalExp.annexaFormly.fields.length > 0 && modalExp.annexaFormly.fields[0].fieldGroup){
						var paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'existDocument'");
						if(paramsField && paramsField.templateOptions && paramsField.templateOptions.options){
							if(documents){
								_.forEach(documents, function(doc){
									paramsField.templateOptions.options.push(doc);
								});
							}
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'existThirdDocument'");
						if(paramsField && paramsField.templateOptions && paramsField.templateOptions.options){
							if(documentsThird){
								_.forEach(documentsThird, function(doc){
									paramsField.templateOptions.options.push(doc);
								});
							}
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'dossierTransactionToAdded'");
						if(paramsField && paramsField.templateOptions && paramsField.templateOptions.options){
							if(vm.box && vm.box.object && vm.box.object.dossierTransactions){
								_.forEach(vm.box.object.dossierTransactions,function(dt){
									paramsField.templateOptions.options.push(dt);
								});
							}
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'loadDocument'");
						if(paramsField && paramsField.templateOptions){
							paramsField.templateOptions.search = searchDocument;
							paramsField.templateOptions.advancedSearch = advancedSearchDocument;
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createDocument'");
						if(paramsField && paramsField.templateOptions){
							paramsField.templateOptions.executeFunction = addCreatedNewDocument;
							paramsField.templateOptions.label = ((modalExp.annexaFormly.model.modal_body.createDocument)?modalExp.annexaFormly.model.modal_body.createDocument:'');
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createInteroperableDocument'");
						if(paramsField && paramsField.templateOptions){
							paramsField.templateOptions.executeFunction = addCreatedNewInteroperableDocument;
							paramsField.templateOptions.label = ((modalExp.annexaFormly.model.modal_body.createInteroperableDocument)?modalExp.annexaFormly.model.modal_body.createInteroperableDocument:'');
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createDossierTemplateDocument'");
						if(paramsField && paramsField.templateOptions){
							paramsField.templateOptions.executeFunction = addCreatedNewDossierTemplateDocument;
							paramsField.templateOptions.label = ((modalExp.annexaFormly.model.modal_body.createDossierTemplateDocument)?modalExp.annexaFormly.model.modal_body.createDossierTemplateDocument:'');
						}
						paramsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'createDigitalizationDocument'");
						if(paramsField && paramsField.templateOptions){
							paramsField.templateOptions.executeFunction = addCreatedNewDigitalizationDocument;
							paramsField.templateOptions.label = ((modalExp.annexaFormly.model.modal_body.createDigitalizationDocument)?modalExp.annexaFormly.model.modal_body.createDigitalizationDocument:'');
						}
					}
					AnnexaFormlyFactory.showModal('modalExpressionEvaluatorAdd', modalExp, addDocument_toDossier, false);
				}
				if(vm.box.object && vm.box.object.id){
		        	var promises = [];
					promises.push(TramNewFactory.getDossierTransactionDocuments(vm.box.object.id));
					if(item.scopeApplication === 'THIRD'){
						promises.push(TramNewFactory.getDossierThird(vm.box.object.id, item.third.id));
					}
			        $q.all(promises).then(function(data) {
						var dossierThird = undefined;
						if(data && data.length > 0){
							if(data.length > 1 && data[1] && data[1].length > 0){
		            			dossierThird = JSOG.decode(data[1])[0];
							}
							if(data[0] && data[0].length > 0){
		                        var dataDocuments = JSOG.decode(data[0]);
								var docs = [];
								var docsThirds = [];
			                    _.forEach(dataDocuments, function (value2, key2) {
									if(value2 && value2.document && value2.document.type && value2.document.type.id && item && item.documentType && item.documentType.id && value2.document.type.id === item.documentType.id){
			                        	docs.push({id: value2.document.id, name: value2.document.name});
										if(item && item.third && item.third.id && value2.dossierThird && value2.dossierThird.third && value2.dossierThird.third.id && item.third.id === value2.dossierThird.third.id){
											docsThirds.push({id: value2.document.id, name: value2.document.name});
										}
									}
		                        });
								showDossierModel(docs, docsThirds, dossierThird);
							}else{
								showDossierModel([], [], dossierThird);
							}							
						}
    				}).catch(function() {
                    })
				}else{
					showDossierModel([], []);
				}
			}

			vm.updateStatus = function(item, status){
				var update = function(item, document, dossierTransaction, dossierThird){
					if(item){
						if(vm.box &&  vm.box.updateFunction){
							var itemAux = angular.copy(item);
							itemAux.status = status
							itemAux.document = document;
							itemAux.dossierTransaction = dossierTransaction;
							itemAux.dossierThird = dossierThird;
							vm.box.updateFunction(itemAux).then(function(data) {
								data = JSOG.decode(data.data);
								item.id = data.id
								item.createdDate = data.createdDate;
								item.deleted = data.deleted;
								item.revision = data.revision;
								item.status = undefined;
								item.document = data.document;
								item.status = data.status;
								if(item.scopeApplication === 'THIRD' && item.deleted){
									var indexThird = undefined;
									var indexDocument = undefined;
									_.forEach(vm.box.content.thirds, function(th, index){
										if(th.third && th.third.id && item.third && item.third.id && item.third.id == th.third.id){
											indexThird = index;
											_.forEach(th.documents, function(doc, indexDoc){
												if(doc.id && item.id && item.id == doc.id){
													indexDocument = indexDoc;
												}
											});
										}
										
									});
									if(indexThird != undefined && indexDocument != undefined){
										vm.box.content.thirds[indexThird].documents.splice(indexDocument,1);
										if(vm.box.content.thirds[indexThird].documents.length === 0){
											vm.box.content.thirds.splice(indexThird, 1);
										}
									}
								}
								vm.calculateClass(item);
								vm.recalculateMassiveButton();
			                }).catch(function (error) {
								if(error && error.data && error.data.message === "No dtp to update"){
									DialogsFactory.error($filter('translate')('global.tram.literals.notExistDocumentation'));
								}else if(error && error.data && error.data.message === "Document is required"){
									DialogsFactory.error($filter('translate')('global.tram.literals.documentIsRequiredInDocumentation'));
								}else if(error && error.data && error.data.message === "Incorrect state to update dtp"){
									DialogsFactory.error($filter('translate')('global.tram.literals.incorretState'));
								}else if(error && error.data && error.data.message === "No status to change"){
									DialogsFactory.error($filter('translate')('global.tram.literals.stateNotExist'));
								}else if(error && error.data && error.data.message === "User cannot change status"){
									DialogsFactory.error($filter('translate')('global.tram.literals.userNotHavePermission'));
								}else{
									DialogsFactory.error($filter('translate')('global.tram.literals.errorUpdateStatusDocumentation'));
								}
			            	});
						}else{
							item.status = undefined;
							item.document = document;
							item.status = status;
							vm.calculateClass(item);
						}
					}					
				}
				if(status === 'ADDED'){
					if(vm.box && vm.box.type === "DOSSIER"){
						markAddedDossier(item, update)
					}
				}else{
					var text = "global.tram.literals.modifyStatusPendingConfirm";
					if(status === 'REQUIRED'){
						text = "global.tram.literals.modifyStatusRequiredConfirm";
					}else if(status === 'NO_ADDED'){
						text = "global.tram.literals.modifyStatusNoAddedConfirm";
					}
					vm.recalculateMassiveButton();
					DialogsFactory.confirm('global.tram.literals.modifyStatusText', text).then(function (data) {
						update(item);
			        }).catch(function (data) {
			               //Empty
					});
				}
			}
			vm.recalculateMassiveButton = function(){
				if(vm.box && vm.box.new){
					vm.box.new.showMassiveInteroperable = false;
					if(vm.box && vm.box.content && vm.box.content.globals && vm.box.content.globals.length > 0){
						_.forEach(vm.box.content.globals, function(global){
							if(vm.box.content.edit && !vm.box.new.showMassiveInteroperable && global.interoperability && global.status === 'PENDING'){
								vm.box.new.showMassiveInteroperable = true;
							}
						});
					}
					if(vm.box && vm.box.content && vm.box.content.thirds && vm.box.content.thirds.length > 0){
						_.forEach(vm.box.content.thirds, function(third){
							if(third.documents){
								_.forEach(third.documents, function(global){
									if(vm.box.content.edit && !vm.box.new.showMassiveInteroperable && global.interoperability && global.status === 'PENDING'){
										vm.box.new.showMassiveInteroperable = true;
									}
								});
							}
						});				
					}
				}	
			}	
					
			vm.calculateClass = function(item){
				if(item.status === 'ADDED'){
					item.class = "fa fa-circle text-success text-lg fa-stack-1x";
				}else if(item.status === 'REQUIRED'){
					item.class = "fa fa-adjust text-blue text-lg fa-stack-1x";
				}else if(item.status === 'NO_ADDED'){
					if(item.id && item.required){
						item.class = "fa fa-circle text-danger text-lg fa-stack-1x";
					}else{
						item.class = "fa fa-circle text-blue text-lg fa-stack-1x";
					}
				}else{
					item.class = "fa fa-circle-o text-blue text-lg fa-stack-1x";
				}
			}
			
			var initializeDocProv = function(global){
				vm.calculateClass(global);
				if(!global.status){
					global.status = 'PENDING';
				}
				if(global.id && global.required){
					global.required = true;
				}else{
					global.required = false;
				}
				if(global.id && global.original){
					global.original = true;
				}else{
					global.original = false;
				}
			}
			
			this.$onInit = function () {
				if(!vm.box){
					vm.box = {};
				}
				if(vm.box && vm.box.content && vm.box.content.edit){
					vm.editBox = true;
				}else{
					vm.editBox = false;
					vm.classDocType = "col-sm-3";
					vm.classDescription = "col-sm-4";
					vm.classStatus = "col-sm-2";
				}
				if(vm.box && vm.box.content && vm.box.content.globals && vm.box.content.globals.length > 0){
					vm.showContent = true;
					_.forEach(vm.box.content.globals, function(global){
						initializeDocProv(global);
					});
				}
				if(vm.box && vm.box.content && vm.box.content.thirds && vm.box.content.thirds.length > 0){
					vm.showContent = true;		
					_.forEach(vm.box.content.thirds, function(third){
						if(third.documents){
							_.forEach(third.documents, function(global){
								initializeDocProv(global);
							});
						}
					});				
				}				
				$scope.$on('annexaBoxObjectDossierDocumentationToProvideUpdate', function(event, args) {
		            if(args.thirds && args.thirds.length > 0) {
						if(args.type == 'DELETE'){
							_.forEach(args.thirds, function(third){
								deleteThird(third);
							});
						}else if(args.type == 'UPDATE' || args.type == 'INSERT'){
							insertOrUpdateThird(args.thirds);
						}
					}
		        });
				
				var deleteDocuments = function(documentationThird){
					if(documentationThird && documentationThird.documents){
						var docs = angular.copy(documentationThird.documents);
						documentationThird.documents.length = 0;
						_.forEach(docs, function(doc){
							if(doc && doc.document && doc.document.id){
								documentationThird.documents.push(doc);
							}
						});
					} 
				}
				
				var deleteThird = function(third){
					var index = $linq(vm.box.content.thirds).indexOf("x => x.third && x.third.id == "+third.third.id);
					if(index >= 0){
						var delThird = vm.box.content.thirds[index];
						deleteDocuments(delThird);
						if(delThird.documents && delThird.documents.length === 0){
							vm.box.content.thirds.splice(index, 1);
						}
					}
				}
				
				var insertOrUpdateThird = function(thirds){
					if(thirds && thirds.length > 0 && vm.box && vm.box.object && vm.box.object){
						var thirdIds = $linq(thirds).where("x => x.id").select("x => x.id").toArray();
						TramNewFactory.getDocumentationToProvideThirds(vm.box.object.id, thirdIds).then(function(data) {
							_.forEach(data, function(d){
								if(d && d.third && d.third.id){
									var index = $linq(vm.box.content.thirds).indexOf("x => x.third && x.third.id == "+d.third.id);
									if(index >= 0){
										if(d.documents && d.documents.length > 0){
											_.forEach(d.documents, function(global){
												initializeDocProv(global);
											})
											vm.box.content.thirds[index].documents = d.documents;
											vm.box.content.thirds[index].third = d.third;
										}else{
											vm.box.content.thirds.splice(index, 1);
										}
									}else{
										if(d.documents && d.documents.length > 0){
											_.forEach(d.documents, function(global){
												initializeDocProv(global);
											})
											vm.box.content.thirds.push(d);
										}
									}
								}
							})
		                }).catch(function (error) {
							var a = 0;
		            	});						
					}
				}
            }
        }],
        bindings: {
            box: '='
        }
    });
