angular
    .module('annexaApp')
    .component('annexaLanguageFieldSetLinkedCF', {
        templateUrl: './components/common/annexa-language-field-set-linked-cf/annexa-language-field-set-linked-cf.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addFunction = function(){
   				var modal = angular.copy(CommonAdminModals.addLinkedValueCustomField);
  	            modal.annexaFormly.model = {};
   				modal.annexaFormly.options = { watchAllExpressions: true };
   				var cfField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'parentValue'");
                if(cfField && cfField.templateOptions){
                	cfField.templateOptions.options = ((vm.data && vm.data.options)?vm.data.options:[]); 
                }
   	            AnnexaFormlyFactory.showModal('modalAddLanguageLikedCF', modal, vm.saveNewValue, false);
            }
            
            vm.saveNewValue = function(){
            	if(this && this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
            		if(this.item){
            			this.item.language1 = this.annexaFormly.model.modal_body.language1;
            			this.item.language2 = this.annexaFormly.model.modal_body.language2;
            			this.item.language3 = this.annexaFormly.model.modal_body.language3;
            			this.item.parentValue = this.annexaFormly.model.modal_body.parentValue;
            		}else{
            			var cfValue = 1;
            			if(vm.data && vm.data.listValues && vm.data.listValues.length > 0){
            				cfValue = $linq(vm.data.listValues).max("x => x.value");
            				cfValue = cfValue+1;
            			}
            			vm.data.listValues.push({language1: this.annexaFormly.model.modal_body.language1, language2: this.annexaFormly.model.modal_body.language2, language3: this.annexaFormly.model.modal_body.language3, parentValue: this.annexaFormly.model.modal_body.parentValue, value: cfValue});
            		}
            	}
            	this.close();
            }

        	vm.editFunction = function(item){
            	var modal = angular.copy(CommonAdminModals.addLinkedValueCustomField);
            	modal.item = item;
  	            modal.annexaFormly.model = {modal_body:angular.copy(item)};
   				modal.annexaFormly.options = { watchAllExpressions: true };
   				var cfField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'parentValue'");
                if(cfField && cfField.templateOptions){
                	cfField.templateOptions.options = ((vm.data && vm.data.options)?vm.data.options:[]); 
                }
   	            AnnexaFormlyFactory.showModal('modalAddLanguageLikedCF', modal, vm.saveNewValue, false);
            }
            
        	vm.removeFunction = function(item, index){
        		DialogsFactory.confirm('global.literals.confirmRemoveLinkedTitle', 'global.literals.confirmRemoveLinkedBody').then(function (dataAux) {
        			if(item){
        				var itemIndex = -1;
        				if(item.id){
        					itemIndex = $linq(vm.data.listValues).indexOf("x => x.id == "+item.id);
        				}else{
        					itemIndex = index;
        				}
        				if(itemIndex > -1){
        					vm.data.listValues.splice(itemIndex, 1);
        				}
        			}
        		}).catch(function (data) {
                    //Empty
        		});
            }
        	
            this.$onInit = function(){
            	$rootScope.$on('modifyLinkedCustomFieldFromCF', function(event, args) {
                	vm.data.options.length = 0;
                	if(args && args.item && args.item.id){
                		var newItem = $linq(GlobalDataFactory.customFields).firstOrDefault(undefined, "x => x.id == "+args.item.id);
                		if(newItem && newItem.listValues){
                			_.forEach(newItem.listValues, function(val){
                				vm.data.options.push(val);
                			});
                		}
                	}
                });
            }
            
        }],
        bindings: {
        	data: '='
        }
    })